import React, {Component} from "react";
import {Input} from "../../components/form/Input";
import {Field} from "formik";
import axios from "axios";
import {injectIntl} from "react-intl";
import {Card, CardBody, CardHeader, ModalProgressBar} from "../../../_metronic/_partials/controls";

class Gateways extends Component {
    state = {
        gateways: [],
        loading: true
    }

    componentDidMount() {
        axios("/api/gateways?sizePerPage=99999").then(response => {
            let gateways = response.data.data;

            this.setState({gateways: gateways, loading: false})
        });
    }

    render() {
        return(
            <Card>
                {this.state.loading && <ModalProgressBar />}
                <CardHeader title={this.props.intl.formatMessage({id: "Moyens d'alarme"})} />
                <CardBody>
                    <Field
                        name="gateways"
                        component={Input}
                        type={"checkbox-list"}
                        withFeedbackLabel={true}
                        data={this.state.gateways}
                        inline={false}
                        disabled={this.props.loading}
                        {...this.props.formikProps}
                    />
                </CardBody>
            </Card>
        )
    }
}

export default injectIntl(Gateways);
