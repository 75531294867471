import React, {useState} from "react";
// eslint-disable-next-line no-restricted-imports
import TextField from '@material-ui/core/TextField';
// eslint-disable-next-line no-restricted-imports
import FormControlLabel from '@material-ui/core/FormControlLabel';
// eslint-disable-next-line no-restricted-imports
import Checkbox from '@material-ui/core/Checkbox';
// eslint-disable-next-line no-restricted-imports
import Select from '@material-ui/core/Select';
// eslint-disable-next-line no-restricted-imports
import MenuItem from '@material-ui/core/MenuItem';
// eslint-disable-next-line no-restricted-imports
import InputLabel from '@material-ui/core/InputLabel';
// eslint-disable-next-line no-restricted-imports
import FormControl from '@material-ui/core/FormControl';
import {FieldArray, isFunction} from "formik";
import {Button, createMuiTheme, MuiThemeProvider, Radio, RadioGroup} from "@material-ui/core";
import {Thumb} from "../Thumb";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
import { DatePicker } from "@material-ui/pickers";
import {Switch} from "./Switch";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control", "mb-7"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function Input({
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    field, // { name, value, onChange, onBlur }
    parent = null,
    index,
    label,
    withFeedbackLabel = true,
    setFieldValue,
    values,
    customFeedbackLabel,
    type = "text",
    valueField = "value",
    labelField = "label",
    extraPivotField = "",
    extraPivotFieldLabel = "",
    inline = true,
    disabled = false,
    ...props
}) {

    const [page, setPage] = useState(1);

    const onChangeFileHandler = (event) =>{
        setFieldValue(field.name, event.currentTarget.files[0]);
    }

    const getLabel = (row, labelField) => {
        let label = '';

        if(isFunction(labelField))
            label = labelField(row);
        else if(labelField === "label")
            label = row.name;
        else
            label = row[labelField];

        let style = {};

        if(row.color) {
            style = {
                backgroundColor: row.color,
                paddingTop: "2px",
                paddingBottom: "2px",
                paddingRight: "10px",
                paddingLeft: "10px",
                borderRadius: "9px",
            }
        }

        return <span style={style}>{label}</span>;
    }

    const getRadioButtonLabel = (labelField, data) => {
        let label = labelField === "label" ? data.name : data[labelField];

        let style = {};

        if(data.color) {
            style = {
                backgroundColor: data.color,
                paddingTop: "2px",
                paddingBottom: "2px",
                paddingRight: "10px",
                paddingLeft: "10px",
                borderRadius: "9px",
            }
        }

        return <span style={style}>{label}</span>;
    };

    const sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    const fields = type => {
        switch (type) {
            case "text":
            case "password":
                return <TextField
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    label={label}
                    error={!!errors[field.name]}
                    disabled={disabled}
                    type={type}
                    {...field}
                    {...props}
                />
            case "checkbox":
                let isChecked = !!values[field.name];

                if(parent)
                    isChecked = !!values[parent][index][field.name];

                return <FormControlLabel
                    className={"mt-5" + getFieldCSSClasses(touched[field.name], errors[field.name])}
                    style={{width: "100%"}}
                    checked={isChecked}
                    onChange={() => {
                        if(parent)
                            setFieldValue(`${parent}.${index}.${field.name}`, !values[parent][index][field.name])
                        else
                            setFieldValue(field.name, !values[field.name])
                    }}
                    control={
                        <Checkbox />
                    }
                    label={label}
                    disabled={disabled}
                />;
            case "switch":
                let isSwitched = !!values[field.name];

                if(parent)
                    isSwitched = !!values[parent][index][field.name];

                return <FormControlLabel
                    className={"mt-5" + getFieldCSSClasses(touched[field.name], errors[field.name])}
                    style={{width: "100%"}}
                    checked={isSwitched}
                    onChange={() => {
                        if(parent)
                            setFieldValue(`${parent}.${index}.${field.name}`, !values[parent][index][field.name])
                        else
                            setFieldValue(field.name, !values[field.name])
                    }}
                    control={
                        <Switch color={'primary'} />
                    }
                    label={label}
                    disabled={disabled}
                />;
            case "date":
                console.log(values[field.name]);
                return <DatePicker
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    label={label}
                    error={!!errors[field.name]}
                    disabled={disabled}
                    format="dd.MM.yyyy"
                    value={values[field.name]}
                    animateYearScrolling
                    onChange={(date) => {
                        setFieldValue(field.name, date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2))
                    }}
                />
            case "file":
                return <div>
                    <br/><label>{label}</label><br/>
                    <input
                        style={{ display: 'none' }}
                        id={"file-" + field.name}
                        type="file"
                        onChange={onChangeFileHandler}
                    />
                    <label htmlFor={"file-" + field.name}>
                        <Button variant="outlined" component="span">
                            Télécharger
                        </Button>
                    </label>
                    <br />
                    <Thumb file={values[field.name]} />
                </div>
            case "checkbox-list":
                return <FieldArray
                    render={arrayHelpers => (
                        <>
                            {props.data.map((data, idx) => {
                                if(idx >= ((50 * page) - 50) && idx < (50 * page))
                                    return <span key={idx} style={{display: inline ? "inline" : "block"}}>
                                    <FormControlLabel
                                        key={idx}
                                        checked={values[field.name].filter(e => e.id === data.id).length > 0}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                data.pivot = {};
                                                arrayHelpers.push(data);

                                                if (props.handleOnChange)
                                                    props.handleOnChange(data, true)
                                            } else {
                                                let idx = 0;

                                                values[field.name].forEach((e, index) => {
                                                    if (e.id === data.id)
                                                        idx = index;
                                                });

                                                arrayHelpers.remove(idx);

                                                if (props.handleOnChange)
                                                    props.handleOnChange(data, false)
                                            }
                                        }}
                                        control={
                                            <Checkbox />
                                        }
                                        //label={labelField === "label" ? data.name : data[labelField]}
                                        label={getLabel(data, labelField)}
                                        color={"primary"}
                                        disabled={disabled}
                                    />
                                    {extraPivotField &&
                                        <TextField
                                            key={idx}
                                            onChange={e => {
                                                let value = {};
                                                values[field.name].forEach((e, index) => {
                                                    if (e.id === data.id) {
                                                        idx = index;
                                                        value = e;
                                                    }
                                                });

                                                if (!value.pivot)
                                                    value.pivot = {};

                                                value.pivot[extraPivotField] = e.target.value;

                                                arrayHelpers.replace(idx, value);
                                            }}
                                            value={(field.value && field.value.filter(e => e.id === data.id).length > 0) ? field.value.filter(e => e.id === data.id)[0].pivot.recipient : ""}
                                            disabled={values[field.name].filter(e => e.id === data.id).length === 0 || disabled}
                                            className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                                            label={extraPivotFieldLabel}
                                        />
                                    }
                                </span>
                            })}

                            {props.data.length > 50 && (
                                <span>
                                    Pages: {sliceIntoChunks(props.data, 50).map((data, idx) => {
                                        idx++;

                                        return <span key={idx}><a onClick={() => setPage(idx)}>{idx}</a>, </span>
                                    })}
                                </span>
                            )}
                        </>
                    )}
                    {...field}
                    {...props}
                />
            case "radiobutton-list":
                return <div>
                    <RadioGroup
                        value={values[field.name]}
                        onChange={(event) => { setFieldValue(field.name, event.target.value); props.handleOnChange(event.target.value); }}
                    >
                        {props.data.map((data, idx) => (
                            <span key={idx} style={{display: inline ? "inline" : "block"}}>
                                <FormControlLabel
                                    key={idx}
                                    value={data.id.toString()}
                                    control={
                                        <Radio />
                                    }
                                    label={getRadioButtonLabel(labelField, data)}
                                    disabled={disabled}
                                />
                            </span>
                        ))}
                    </RadioGroup>
                </div>
            case "select":
                return <FormControl style={{width: "100%"}} className={"mb-5" + getFieldCSSClasses(touched[field.name], errors[field.name])}>
                    <InputLabel htmlFor={"select-" + field.name}>{label}</InputLabel>
                    <Select
                        inputProps={{
                            id: 'select-' + field.name,
                        }}
                        error={!!errors[field.name]}
                        disabled={disabled}
                        {...field}
                        {...props}
                    >
                        {props.data.map((data) => {
                            return <MenuItem key={data[valueField]} value={data[valueField]}>{data[labelField]}</MenuItem>
                        })}
                    </Select>
                </FormControl>;
            default:
                return <></>;
        }
    };

    if(field.value == null)
        field.value = "";

    return (
        <>
            {fields(type)}
            {(withFeedbackLabel && errors[field.name]) && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}
        </>
    );
}
