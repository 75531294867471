import React, {useContext, useEffect, useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import {utils} from "../../library/Utils";
import {ApplicationContext} from "../../library/ApplicationContext";
import {Field, Formik, Form as Formular} from "formik";
import {Input} from "../../components/form/Input";
import {TextField} from "@material-ui/core";

export const Import = ({history, intl}) => {
    const suhbeader = useSubheader();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({customer: null, role: null, step: 1, file: null});
    const { application } = useContext(ApplicationContext);

    const save = (values, setErrors) => {
        setLoading(true);

        const url = "api/users/import";

        axios({method: "POST", url: url, data: utils.objectToFormData(values, "POST")}).then(response => {
            setLoading(false);

            setData(response.data);
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            setLoading(false);
        });
    };

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Importer des utilisateurs"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Importer des utilisateurs"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => window.location = process.env.REACT_APP_API_URL + 'exemple.csv'}
                        className="btn btn-light mr-5"
                    >
                        {intl.formatMessage({id: "Fichier d'exemple"})}
                        <i className="fa fa-arrow-down" />
                    </button>

                    <button
                        type="button"
                        onClick={() => history.push("/users")}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left" />
                        {intl.formatMessage({id: "Précédent"})}
                    </button>

                    {data.step !== 3 && (
                        <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={() => handleSubmit()}
                            disabled={loading}
                        >
                            {intl.formatMessage({id: "Suivant "})}
                            <i className="fa fa-arrow-right" />
                        </button>
                    )}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={(values, { setErrors }) => {
                        save(values, setErrors);
                    }}
                >
                    {props => {
                        handleSubmit = props.handleSubmit;

                        return (
                            <Formular className="form form-label-right">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2 col-12 offset-0">
                                        {data.errors !== undefined && (
                                            data.errors.map(e => {
                                                return <div className="alert alert-danger">
                                                    {e}
                                                </div>
                                            })
                                        )}

                                        {data.step === 1 && (
                                            <>
                                                <Field
                                                    name="role"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Role"})}
                                                    data={application.catalog.roles}
                                                    valueField={"id"}
                                                    labelField={"name"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="file"
                                                    component={Input}
                                                    type={"file"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Fichier CSV"})}
                                                    {...props}
                                                />
                                            </>
                                        )}

                                        {data.step === 2 && (
                                            <>
                                                <Field
                                                    name="name"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Nom"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="firstname"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Prénom"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="email"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "E-Mail"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="mobile"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Mobile"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="phone"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Téléphone"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                <Field
                                                    name="groups"
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Groupes (séparé par des virgules)"})}
                                                    data={data.headers}
                                                    valueField={"value"}
                                                    labelField={"label"}
                                                    disabled={loading}
                                                />
                                                {data.customFields.map(e =>
                                                    <>
                                                        <Field
                                                            key={e.id}
                                                            name={`customField.${e.id}`}
                                                            component={Input}
                                                            type={"select"}
                                                            withFeedbackLabel={true}
                                                            label={e.name}
                                                            data={data.headers}
                                                            valueField={"value"}
                                                            labelField={"label"}
                                                            disabled={loading}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {data.step === 3 && (
                                            <center><b>{intl.formatMessage({id: "Importation terminée"})}</b></center>
                                        )}
                                    </div>
                                </div>
                            </Formular>
                        )}
                    }
                </Formik>
            </CardBody>
        </Card>
    );
};
