import React, {useContext, useEffect, useState} from "react";
import {IntlProvider} from "react-intl";

import frMessages from "./messages/fr.json";
import deMessages from "./messages/de.json";
import enMessages from "./messages/en.json";
import itMessages from "./messages/it.json";

import {Languages} from "../library/Enums/Languages";
import {ApplicationContext} from "../library/ApplicationContext";

export const I18nProvider = ({ children, user }) => {
    const { application, setApplication } = useContext(ApplicationContext);
    const [language, setLanguage] = useState({locale: 'fr', messages : frMessages});

    useEffect(() => {
        if(application !== undefined) {
            application.updateLanguage = (data) => {

                application.user = data;
                setApplication(application);

                updateLanguage(data);
            };

            setApplication(application);

            if (user) {
                updateLanguage(user);
            }
        }
    }, [application, setApplication, user]);

    const updateLanguage = (user) => {
        let locale = 'fr';
        let messages = frMessages;

        switch (parseInt(user.language)) {
            case Languages.FRENCH:
                locale = 'fr';
                messages = frMessages;
                break;
            case Languages.GERMAN:
                locale = 'de';
                messages = deMessages;
                break;
            case Languages.ENGLISH:
                locale = 'en';
                messages = enMessages;
                break;
            case Languages.ITALIAN:
                locale = 'it';
                messages = itMessages;
                break;
            default:
                locale = 'fr';
                messages = frMessages;
        }

        setLanguage({locale: locale, messages: messages});
    };

    const onError = () => {

    };

    return (
        <IntlProvider locale={language.locale} messages={language.messages} onError={onError}>
            {children}
        </IntlProvider>
    );
}
