import React from "react";
import {ModalProgressBar} from "../../_metronic/_partials/controls";
import {Modal} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {ApplicationContext} from "../library/ApplicationContext";
// eslint-disable-next-line no-restricted-imports
import FormControl from '@material-ui/core/FormControl';
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import axios from "axios";
import {toAbsoluteUrl} from "../../_metronic/_helpers";

export class SwitchUser extends React.Component {
    static contextType = ApplicationContext;

    constructor(props) {
        super(props);

        this.state = {selectedCustomer : null, show: false, bg: (Math.floor(Math.random() * 5) + 1)}
    }

    componentDidMount() {
        this.setState({selectedCustomer : this.context.application.user.customer.id, show: (!localStorage.getItem('userSelected') && this.context.application.user.relatedUserAccounts.length > 1)});
    }

    accountList() {
        return this.context.application.user.relatedUserAccounts.map(u => {
            return <FormControlLabel key={u.id} value={u.customer.id} control={<Radio />} label={u.customer.name}/>
        });
    }

    handleChange(event) {
        this.setState({selectedCustomer: event.target.value})
    }

    selectCustomer() {
        axios("/api/users/switch-user/" + this.state.selectedCustomer).then(response => {
            const authItem = JSON.parse(localStorage.getItem('persist:helpro-auth'));

            localStorage.clear();

            authItem.authToken = '"' + response.data.accessToken + '"';
            authItem.user = JSON.stringify(response.data.user);

            localStorage.setItem('userSelected', true);
            localStorage.setItem('persist:helpro-auth', JSON.stringify(authItem));

            window.location.reload();
        });
    }

    render() {
        let show = this.state.show;
        let deleting = false;
        let value = parseInt(this.state.selectedCustomer);

        return <>
            {(this.props.showButton && this.context.application.user.relatedUserAccounts.length > 1) && (
                <button
                    type="button"
                    className="btn btn-primary btn-elevate"
                    disabled={this.state.selectedCustomer === null}
                    onClick={() => this.setState({show: true})}
                >
                    {"Changer de client"}
                </button>
            )}
            <Modal
                show={show}
                aria-labelledby="modal-sizes-title-lg"
                style={{
                    backgroundImage: `url(${toAbsoluteUrl("/media/background-switch-" + this.state.bg + ".jpg")})`,
                    backgroundSize: "cover"
                }}
            >
                {deleting && <ModalProgressBar variant="query" />}
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">
                        {"Sélection d'un client"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={this.handleChange.bind(this)}
                        >
                            {this.accountList()}
                        </RadioGroup>
                    </FormControl>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            type="button"
                            className="btn btn-primary btn-elevate"
                            disabled={this.state.selectedCustomer === null}
                            onClick={this.selectCustomer.bind(this)}
                        >
                            {"Sélectionner"}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    }
}

export default injectIntl(SwitchUser);
