/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import axios from "axios";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function Changelog({ className }) {
    const [loading, setLoading] = useState(false);
    const [changelog, setChangelog] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            const changelog = await axios("/api/dashboard/changelog");

            setChangelog(changelog.data);

            setLoading(false);
        };

        if (changelog.length === 0 && !loading) {
            getData();
        }
    });

    return (
        <>
            <div className={`card card-custom  ${className}`}>
                {loading && <ModalProgressBar />}
                {/* Header */}
                <div className="card-header align-items-center border-0 mt-4">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="font-weight-bolder text-dark">{intl.formatMessage({ id: "Changelog" })}</span>
                    </h3>
                </div>
                {/* Body */}
                <div className="card-body pt-4">
                    <div className="timeline timeline-6 mt-3">
                        {changelog.map((log, idx) => {
                            return <div className="timeline-item align-items-start" key={idx}>
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg" style={{width: "90px"}}>
                                    {log.date}
                                </div>

                                <div className="timeline-badge">
                                    <i className="fa fa-genderless text-success icon-xl"></i>
                                </div>

                                <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                                    {log.version}: {log.message}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}
