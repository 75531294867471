/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {useContext, useState} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import ApplicationRoutes from "./ApplicationRoutes";
import { Logout, AuthPage } from "./modules/Auth";
import {Layout} from "./Layout";
import {I18nProvider} from "./i18n";
import {ApplicationContext} from "./library/ApplicationContext";
import axios from "axios";
import {SwitchUser} from "./components/SwitchUser";

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    const Catalog = (props) => {
        const { application, setApplication } = useContext(ApplicationContext)

        if(application.catalog.gateways.length === 0)
            axios("/api/catalog").then(catalogResponse => {
                axios("/api/profil").then(response => {
                    setApplication({catalog: catalogResponse.data.data, user: response.data});
                });
            });

        if(application.catalog.gateways.length === 0)
            return <></>;
        else
            return props.children;
    }

    const [application, setApplication] = useState({catalog: {gateways: [], modules: [], roles: []}});
    const value = {application, setApplication}

    if(!isAuthorized && application.catalog.gateways.length > 0)
        setApplication({catalog: {gateways: [], modules: [], roles: []}, user: null});

    if(window.location.href.indexOf('login-with-token') !== -1) {
        axios("/api/login-with-token/" + window.location.href.split("/")[4]).then(response => {
            const authItem = {authToken: '"' + response.data.accessToken + '"', user: JSON.stringify(response.data.user)};

            localStorage.clear();

            localStorage.setItem('userSelected', true);
            localStorage.setItem('persist:helpro-auth', JSON.stringify(authItem));

            window.location = '/notifications/';
        });

        return <></>;
    } else if(window.location.href.indexOf('new.helpro.ch') !== -1) {
        window.location = 'https://www.helpro.ch';
    }

  return (
      <Switch>
          {!isAuthorized ? (
              /*Render auth page when user at `/auth` and not authorized.*/
              <Route>
                  <I18nProvider>
                      <AuthPage />
                  </I18nProvider>
              </Route>
          ) : (
              /*Otherwise redirect to root page (`/`)*/
              <Redirect from="/auth" to="/" />
          )}

          <Route path="/logout" component={Logout} />

          {!isAuthorized ? (
              /*Redirect to `/auth` when user is not authorized*/
              <Redirect to="/auth/login" />
          ) : (
              <ApplicationContext.Provider value={value}>
                  <Catalog>
                      <I18nProvider user={application.user}>
                          {(localStorage.getItem('userSelected') || (application.user && application.user.relatedUserAccounts.length === 1))&& (
                              <Layout>
                                  <ApplicationRoutes />
                              </Layout>
                          )}
                          {(!localStorage.getItem('userSelected') && (application.user && application.user.relatedUserAccounts.length > 1)) && (
                              <SwitchUser />
                          )}
                      </I18nProvider>
                  </Catalog>
              </ApplicationContext.Provider>
          )}
      </Switch>
  );
}
