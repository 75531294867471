import React, {useEffect, useState} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {Alert} from "react-bootstrap";
import {Chart} from "./Chart";
import {Providers} from "./Providers";
import {Contact} from "./Contact";
import {Changelog} from "./Changelog";
import axios from "axios";

export const Dashboard = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Dashboard");

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [flash, setFlash] = useState();
    const [displayFlash, setDisplayFlash] = useState(true);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            const data = await axios("/api/dashboard/flash");

            setFlash(data.data);

            setLoading(false);
            setLoaded(true);
        };

        if (!loaded && !loading) {
            getData();
        }
    });

    return (<>

        <div className={"row"}>
            {displayFlash && flash && (
                <div className="col-lg-12 col-xxl-12">
                    <Alert variant="danger" onClose={() => setDisplayFlash(false)} dismissible>
                        <Alert.Heading>{flash.title}</Alert.Heading>
                        <p>
                            {flash.message}
                        </p>
                    </Alert>
                </div>
            )}

            <div className="col-lg-6 col-xxl-8">
                <Chart className="gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4">
                <Providers className="card-stretch gutter-b" />
            </div>

            <div className="col-lg-6 col-xxl-6">
                <Contact className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-6">
                <Changelog className="card-stretch gutter-b" />
            </div>
        </div>
    </>);
};
