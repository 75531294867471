export const NotificationStatus = {
    REGISTERED: 1,
    PENDING: 2,
    SENT: 3,
    FAILED: 4,
    DELIVERED: 5,
    UNDELIVERED: 6,
    OUT_OF_RANGE: 7,
    ACKNOWLEDGED: 8,
    NONE: 9999
};
