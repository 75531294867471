import React, {Component} from "react";
import {Input} from "../../components/form/Input";
import {Field, isObject} from "formik";
import axios from "axios";
import {default as InputTextField} from "@material-ui/core/Input/Input";
import {injectIntl} from "react-intl";
import {Card, CardBody, CardHeader, ModalProgressBar} from "../../../_metronic/_partials/controls";

class Groups extends Component {
    state = {
        data: [],
        groups: [],
        filter: "",
        loading: true
    }

    filter(groups) {
        if(this.props.parentGroups)
            groups = groups.filter(group => group.parent ? this.props.parentGroups.map(parentGroup => parentGroup.id).includes(group.parent.id) : false);

        return groups;
    }

    componentDidMount() {
        axios("/api/notifications/groups").then(response => {
            let groups = response.data;

            groups = groups.filter(group => !group.hasChildren);

            groups = this.filter(groups);

            this.setState({data: response.data, groups: groups, loading: false});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.parentGroups !== this.props.parentGroups) {
            let groups = this.filter(this.state.data);

            this.setState({groups: groups});
        }
    }

    selectUsers(row, add) {
        let group;

        if(isObject(row))
            group = parseInt(row.id);
        else {
            group = parseInt(row);

            const initialMessage = this.state.groups.filter(e => e.id === group)[0].initialMessage;

            if(initialMessage) {
                //this.props.formikProps.setFieldValue("content", initialMessage);

                this.props.setGroupMessage(initialMessage, this.props.formikProps.setFieldValue);
            }
        }

        let users = this.props.formikProps.values["users"];

        switch(add) {
            case true:
                users = users.concat(this.state.groups.filter(e => e.id === group)[0].users);

                //remove duplicates user
                users = users.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)

                this.props.formikProps.setFieldValue("users", users);
                this.props.formikProps.setFieldValue("users_fromgroups", users);

                break;
            case false:
                users = users.filter(user => !this.state.groups.filter(e => e.id === group)[0].users.map(user => user.id).includes(user.id));

                this.props.formikProps.setFieldValue("users", users);
                this.props.formikProps.setFieldValue("users_fromgroups", users);

                break;
            default:
                this.props.formikProps.setFieldValue("users", this.state.groups.filter(e => e.id === group)[0].users);
                this.props.formikProps.setFieldValue("users_fromgroups", this.state.groups.filter(e => e.id === group)[0].users);
        }
    }

    render() {
        let groups = this.state.groups;

        if(this.state.filter.length > 2)
            groups = groups.filter(group => group.name.toLowerCase().search(this.state.filter) !== -1);

        return(
            <Card>
                {this.state.loading && <ModalProgressBar />}
                <CardHeader title={this.props.intl.formatMessage({id: "Groupes"})} />
                <CardBody>
                    <InputTextField
                        placeholder={this.props.intl.formatMessage({id: "Rechercher"})}
                        style={{marginBottom: 30, width: "100%"}}
                        onChange={e => this.setState({filter: e.target.value})}
                    />
                    <Field
                        name={this.props.fieldName}
                        component={Input}
                        type={this.props.multiple ? "checkbox-list" : "radiobutton-list"}
                        handleOnChange={(row, add) => this.props.multiple ? this.selectUsers(row, add) : this.selectUsers(row, null)}
                        withFeedbackLabel={true}
                        data={groups}
                        inline={false}
                        disabled={this.props.loading}
                        {...this.props.formikProps}
                    />
                </CardBody>
            </Card>
        )
    }
}

export default injectIntl(Groups);
