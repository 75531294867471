import React, {useContext, useMemo} from "react";
import {Link, NavLink} from "react-router-dom";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_metronic/layout/_core/MetronicLayout";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../_metronic/_helpers";
// eslint-disable-next-line no-restricted-imports
import Icon from '@material-ui/core/Icon';
import {ApplicationContext} from "../../library/ApplicationContext";
import {utils} from "../../library/Utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import {injectIntl} from "react-intl";

function Aside({intl}) {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            disableScroll:
                objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
                false,
            asideClassesFromConfig: uiService.getClasses("aside", true),
            disableAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false,
            headerLogo: uiService.getLogo(),
            brandClasses: uiService.getClasses("brand", true),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            )
        };
    }, [uiService]);

    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-open menu-item-not-hightlighted`
            : "";
    };

    const { application } = useContext(ApplicationContext);

    return (
        <>
            {/* begin::Aside */}
            <div id="kt_aside" className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
                <div
                    className={`brand flex-column-auto ${layoutProps.brandClasses}`}
                    id="kt_brand"
                >
                    {/* begin::Logo */}
                    <Link to="" className="brand-logo">
                        <img alt="logo" src={toAbsoluteUrl("/media/logo.png")} style={{width: "50%"}}/>
                    </Link>
                    {/* end::Logo */}

                    {layoutProps.asideSelfMinimizeToggle && (
                        <>
                            {/* begin::Toggle */}
                            <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
                                <span className="svg-icon svg-icon-xl">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}/>
                                </span>
                            </button>
                            {/* end::Toolbar */}
                        </>
                    )}
                </div>

                {/* begin::Aside Menu */}
                <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
                    {layoutProps.disableAsideSelfDisplay && (
                        <>
                            {/* begin::Header Logo */}
                            <div className="header-logo">
                                <Link to="">
                                    <img alt="logo" src={toAbsoluteUrl("/media/logo.png")}/>
                                </Link>
                            </div>
                            {/* end::Header Logo */}
                        </>
                    )}
                    <div
                        id="kt_aside_menu"
                        data-menu-vertical="1"
                        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
                        {...layoutProps.asideMenuAttr}
                    >
                        <PerfectScrollbar
                            options={{
                                wheelSpeed: 2,
                                wheelPropagation: false,
                            }}
                            className="scroll"
                            style={{ maxHeight: window.innerHeight - 80, position: "relative" }}
                        >
                            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                                <li
                                    className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                                    aria-haspopup="true"
                                >
                                    <NavLink className="menu-link" to="/dashboard">
                                        <span className="svg-icon menu-icon">
                                          <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                                        </span>
                                        <span className="menu-text">{intl.formatMessage({id: "Tableau de bord"})}</span>
                                    </NavLink>
                                </li>

                                {(utils.canAccess('notifications', application) || utils.canAccess('template-notifications', application) /*|| utils.canAccess('geofencing', application)*/ || utils.canAccess('history', application)) && (
                                    <>
                                        <li className="menu-section ">
                                            <h4 className="menu-text">{intl.formatMessage({id: "Alarmes"})}</h4>
                                            <i className="menu-icon flaticon-more-v2"></i>
                                        </li>

                                        {utils.canAccess('notifications', application) && (
                                            <li
                                                className={`menu-item mb-2 ${getMenuItemActive("/notifications", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/notifications">
                                                    <span className="svg-icon menu-icon">
                                                        <Icon className="fa fa-bell" style={{overflow: "initial"}} />
                                                    </span>
                                                    <span className="menu-text">{intl.formatMessage({id: "Notifications"})}</span>
                                                </NavLink>
                                            </li>
                                        )}

                                        {(utils.canAccess('template-notifications', application) && application.catalog.templates.length > 0) && (
                                            <li
                                                className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                    "/template-notifications/",
                                                    true
                                                )}`}

                                                data-menu-toggle="hover"
                                            >
                                                <NavLink className="menu-link menu-toggle" to="/template-notifications/">
                                                    <span className="svg-icon menu-icon">
                                                      <Icon className="fa fa-exclamation-circle" style={{overflow: "initial"}} />
                                                    </span>
                                                    {application.catalog.customers.length > 0 && (
                                                        <span className="menu-text">{application.catalog.customers.filter(customer => customer.id.toString() === application.user.currentCustomer.toString())[0].name}</span>
                                                    )}
                                                    {application.catalog.customers.length === 0 && (
                                                        <span className="menu-text">{application.user.customer.name}</span>
                                                    )}
                                                </NavLink>
                                                <div className="menu-submenu" style={{display: 'block'}}>
                                                    <i className="menu-arrow" />
                                                    <ul className="menu-subnav">
                                                        {application.catalog.templates.map((template, index) => (
                                                            <li
                                                                key={index}
                                                                className={`menu-item ${getMenuItemActive("/template-notifications/" + template.id)}`}
                                                            >
                                                                <NavLink className="menu-link" to={"/template-notifications/" + template.id}>
                                                                    <i className="menu-bullet menu-bullet-dot">
                                                                        <span />
                                                                    </i>
                                                                    <span className="menu-text">{template.name}</span>
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}

                                        {/*utils.canAccess('geofencing', application) && (
                                            <li
                                                className={`menu-item mb-2 ${getMenuItemActive("/geofencing", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/geofencing">
                                                    <span className="svg-icon menu-icon">
                                                        <Icon className="fa fa-street-view" style={{overflow: "initial"}} />
                                                    </span>
                                                    <span className="menu-text">{intl.formatMessage({id: "Geofencing"})}</span>
                                                </NavLink>
                                            </li>
                                        )*/}

                                        {utils.canAccess('history', application) && (
                                            <li
                                                className={`menu-item mb-2 ${getMenuItemActive("/history", false)}`}
                                                aria-haspopup="true"
                                            >
                                                <NavLink className="menu-link" to="/history">
                                                    <span className="svg-icon menu-icon">
                                                        <Icon className="fa fa-clock" style={{overflow: "initial"}} />
                                                    </span>
                                                    <span className="menu-text">{intl.formatMessage({id: "Historique des notifications"})}</span>
                                                </NavLink>
                                            </li>
                                        )}
                                    </>
                                )}

                                {(utils.canAccess('users', application) || utils.canAccess('groups', application) || utils.canAccess('messages', application)) && (
                                    <li className="menu-section ">
                                        <h4 className="menu-text">{intl.formatMessage({id: "Gestion clients"})}</h4>
                                        <i className="menu-icon flaticon-more-v2"></i>
                                    </li>
                                )}

                                {utils.canAccess('users', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/users", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/users">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-user" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Utilisateurs"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {utils.canAccess('groups', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/groups", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/groups">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-users" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Groupes"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {utils.canAccess('messages', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/messages", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/messages">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-sticky-note" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Messages prédéfinis"})}</span>
                                        </NavLink>
                                    </li>
                                )}

                                {(utils.canAccess('customers', application) || utils.canAccess('templates', application) || utils.canAccess('roles', application) || utils.canAccess('flashMessages', application)) && (
                                    <li className="menu-section ">
                                        <h4 className="menu-text">{intl.formatMessage({id: "Administration"})}</h4>
                                        <i className="menu-icon flaticon-more-v2"></i>
                                    </li>
                                )}

                                {utils.canAccess('customers', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/customers", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/customers">
                                        <span className="svg-icon menu-icon">
                                            <Icon className="fa fa-user-friends" style={{overflow: "initial"}} />
                                        </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Clients"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {utils.canAccess('templates', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/templates", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/templates">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-file-invoice" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Modèles pour les alarmes"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {utils.canAccess('roles', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/roles", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/roles">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-user-tag" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Rôles"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                                {utils.canAccess('flashMessages', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/flashMessages", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/flashMessages">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-fire-alt" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Messages flash"})}</span>
                                        </NavLink>
                                    </li>
                                )}

                                {(utils.canAccess('credit', application) || utils.canAccess('logs', application) || utils.canAccess('changelog', application)) && (
                                    <li className="menu-section ">
                                        <h4 className="menu-text">{intl.formatMessage({id: "Système"})}</h4>
                                        <i className="menu-icon flaticon-more-v2"></i>
                                    </li>
                                )}

                                {utils.canAccess('credits', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/credits", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/credits">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-dollar-sign" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Crédits fournisseurs"})}</span>
                                        </NavLink>
                                    </li>
                                )}

                                {utils.canAccess('logs', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/logs", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/logs">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-book" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({id: "Log de l'application"})}</span>
                                        </NavLink>
                                    </li>
                                )}

                                {utils.canAccess('changelog', application) && (
                                    <li
                                        className={`menu-item mb-2 ${getMenuItemActive("/changelog", false)}`}
                                        aria-haspopup="true"
                                    >
                                        <NavLink className="menu-link" to="/changelog">
                                            <span className="svg-icon menu-icon">
                                                <Icon className="fa fa-clipboard-list" style={{overflow: "initial"}} />
                                            </span>
                                            <span className="menu-text">{intl.formatMessage({ id: "Changelog"})}</span>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
                {/* end::Aside Menu */}
            </div>
            {/* end::Aside */}
        </>
    );
}

export default injectIntl(Aside);
