import React, {useMemo} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_metronic/layout/_core/MetronicLayout";
import AnimateLoading from "../../../_metronic/_partials/controls/AnimateLoading";
import {Topbar} from "./Topbar";
import {Alert} from "react-bootstrap";

export function Header() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            headerClasses: uiService.getClasses("header", true),
            headerAttributes: uiService.getAttributes("header"),
            headerContainerClasses: uiService.getClasses("header_container", true),
            menuHeaderDisplay: objectPath.get(
                uiService.config,
                "header.menu.self.display"
            )
        };
    }, [uiService]);

    return (
        <>
            {/*begin::Header*/}
            <div
                className={`header ${layoutProps.headerClasses}`}
                id="kt_header"
                {...layoutProps.headerAttributes}
            >
                {/*begin::Container*/}
                <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
                    {/*<AnimateLoading />*/}
                    {process.env.REACT_APP_ENV === 'preprod' && (
                        <Alert variant={"danger"} style={{position: "absolute", marginTop: "11px"}}>
                            Vous êtes sur la plateforme de pré-production.
                        </Alert>
                    )}

                    {process.env.REACT_APP_ENV === 'dev' && (
                        <Alert variant={"danger"} style={{position: "absolute", marginTop: "11px"}}>
                            Vous êtes sur la plateforme de développement.
                        </Alert>
                    )}
                    <div />

                    {/*begin::Topbar*/}
                    <Topbar />
                    {/*end::Topbar*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
        </>
    );
}
