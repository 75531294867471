import React, {useContext, useEffect, useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Form} from "./Form";
import axios from "axios";
import {utils} from "../../library/Utils";
import {ApplicationContext} from "../../library/ApplicationContext";

export const Create = ({history, intl}) => {
    const suhbeader = useSubheader();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({groups: [], customers: []});

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            let groups = await axios("/api/groups?sizePerPage=99999");

            groups = groups.data.data;

            if(groups)
                groups = groups.filter(group => !group.hasChildren);

            setLoading(false);
            setLoaded(true);

            setData({groups: groups});
        };

        if (!loaded && !loading) {
            getData();
        }
    });

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Ajouter un nouvel utilisateur"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Ajouter un nouvel utilisateur"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => history.push("/users")}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left" />
                        {intl.formatMessage({id: "Retour"})}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => handleSubmit()}
                    >
                        {intl.formatMessage({id: "Enregistrer"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Form method={"POST"} groups={data.groups} setHandleSubmit={(func) => {handleSubmit = func}} loading={setLoading} history={history} intl={intl} />
            </CardBody>
        </Card>
    );
};
