import React, {Component} from "react";
import {Input} from "../../components/form/Input";
import {Field} from "formik";
import axios from "axios";
import {Badge} from "react-bootstrap";
import {Gateways} from "../../library/Enums/Gateways";
// eslint-disable-next-line no-restricted-imports
import {default as InputTextField} from '@material-ui/core/Input';
import {injectIntl} from "react-intl";
import {Card, CardBody, CardHeader, ModalProgressBar} from "../../../_metronic/_partials/controls";

class Users extends Component {
    state = {
        data: [],
        users: [],
        groups: [],
        group: null,
        filter: "",
        loading: true
    }

    filter(users) {
        if(this.props.groups)
            users = users.filter(user => user.groups.map(group => group.id).some(current => this.props.groups.map(group => group.id).includes(current)));

        if(this.props.parentGroups)
            users = users.filter(user => user.groups.map(group => group.parent).some(current => this.props.parentGroups.map(group => group.id).includes(current)));

        return users;
    }

    componentDidMount() {
        axios("/api/notifications/users").then(response => {
            let users = this.filter(response.data);

            this.setState({data: response.data, users: users, loading: false})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.groups !== this.props.groups || prevProps.parentGroups !== this.props.parentGroups) {
            let users = this.filter(this.state.data);

            this.setState({users: users});
        }

        /*if(this.state.groups !== this.props.formikProps.values["groups"]) {
            let groups = this.props.formikProps.values["groups"];
            let users = this.props.formikProps.values["users"];

            if(this.props.formikProps.values["groups"].length > 0)
                users = this.state.users.filter(user => user.groups.some(group => groups.some(g => g.id === group.id)));

            let removedGroups = this.state.groups.filter(g => !groups.map(gg => gg.id).includes(g.id));

            if(removedGroups.length > 0) {
                users = users.filter(user => user.groups.map(g => g.id).includes(removedGroups.map(g => g.id)));

                console.log(users);
            }

            this.props.formikProps.setFieldValue("users", users);

            this.setState({groups : groups});
        }

        if(this.state.group !== this.props.formikProps.values["group"]) {
            let group = this.props.formikProps.values["group"];
            let users = this.state.users.filter(user => user.groups.some(g => g.id === parseInt(group)));

            this.props.formikProps.setFieldValue("users", users);
            this.setState({group : group});
        }*/
    }

    render() {
        let users = this.state.users;
        //let gateways = this.props.formikProps.values["gateways"];
        let gateways = this.props.formikProps.values["gateways"].length > 0 ? this.props.formikProps.values["gateways"] : this.props.gateways;
        let selectedUsers = this.props.formikProps.values["users_fromgroups"] ? this.props.formikProps.values["users_fromgroups"].map(user => user.id) : [];

        users = users.filter(user => {
            if (user.user_gateways.length > 0) {
                if(gateways.length === 0 || (gateways.length > 0 && user.user_gateways.some(userGateway => userGateway.status === 1 && gateways.some(gateway => userGateway.gateway.id === gateway.id))))
                    if(this.state.filter.length <= 2 || (this.state.filter.length > 2 && (user.firstname.toLowerCase().search(this.state.filter) !== -1 || user.name.toLowerCase().search(this.state.filter) !== -1)))
                        return user;
            }
        });

        users = users.filter(user => selectedUsers.includes(user.id)).concat(users.filter(user => !selectedUsers.includes(user.id)));

        return(
            <Card>
                {this.state.loading && <ModalProgressBar />}
                <CardHeader title={this.props.intl.formatMessage({id: "Utilisateurs"})} />
                <CardBody>
                    <InputTextField
                        placeholder={this.props.intl.formatMessage({id: "Rechercher"})}
                        style={{marginBottom: 30, width: "100%"}}
                        onChange={e => this.setState({filter: e.target.value})}
                    />
                    <Field
                        name="users"
                        component={Input}
                        type={"checkbox-list"}
                        withFeedbackLabel={true}
                        data={users}
                        inline={false}
                        labelField={(row) => {
                            return <>
                                <span>{row.name} {row.firstname}</span>
                                <div style={{position: "absolute", right: 0, marginTop: "-22px", marginRight: "15px"}}>
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.CALL && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fa fa-phone"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.SMS && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fa fa-sms"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.WHATSAPP && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fab fa-whatsapp"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.PAGER && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fa fa-pager"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.SMARTPHONE && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fa fa-mobile"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                    {row.user_gateways.filter(e => (e.gateway.type === Gateways.EMAIL && e.status === 1)).length > 0 && (
                                        <Badge variant="light" className={"mr-2"}>
                                            <span className={"fa fa-envelope"} style={{width: "15px"}}></span>
                                        </Badge>
                                    )}
                                </div>
                            </>;
                        }}
                        disabled={this.props.loading}
                        {...this.props.formikProps}
                    />
                </CardBody>
            </Card>
        )
    }
}

export default injectIntl(Users);
