import React, { Component } from 'react'
import axios from "axios";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from "../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import {Pagination} from "../../../_metronic/_partials/controls";
import paginationFactory, {
    PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import {persistor} from "../../library/Persistor";
import {injectIntl} from "react-intl";

export class Table extends Component {

    constructor(props) {
        super(props);

        let preservePagination = true;

        if(props.preservePagination !== undefined && !props.preservePagination)
            preservePagination = false;

        this.state = {
            data: props.data ? props.data.data : [],
            loading: false,
            /*sortOrder: persistor.get(props.id, "sortOrder", null),
            sortField: persistor.get(props.id, "sortField", null),
            filter: persistor.get(props.id, "filter", ""),
            sizePerPage: preservePagination ? persistor.get(props.id, "sizePerPage", 10) : 10,
            page: preservePagination ? persistor.get(props.id, "page", 1) : 1,*/
            sortOrder: null,
            sortField: null,
            filter: '',
            sizePerPage: 10,
            page: 1,
            totalSize: props.data ? props.data.meta.total : 0,
            interval: null
        };
    }

    componentDidMount() {
        if(this.state.data.length === 0)
            this.load();

        if(this.props.refreshAuto) {
            const interval = setInterval(() => this.load(), 5000);

            this.setState({interval: interval});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.deleting && !this.props.deleting || prevProps.extraQuery !== this.props.extraQuery) {
            this.load();
        }

        if(this.props.data && (JSON.stringify(this.props.data.data) !== JSON.stringify(prevProps.data.data))) {
            let preservePagination = true;

            if(this.props.preservePagination !== undefined && !this.props.preservePagination)
                preservePagination = false;

            this.setState({
                data: this.props.data ? this.props.data.data : [],
                totalSize: this.props.data ? this.props.data.meta.total : 0,
                page: preservePagination ? this.state.page : 1,
                sizePerPage: preservePagination ? this.state.sizePerPage : 10
            });
        }
    }

    componentWillUnmount () {
        if(this.axiosCancelSource)
            this.axiosCancelSource.cancel('Axios request canceled.')

        if(this.state.interval)
            clearInterval(this.state.interval);
    }

    load() {
        let query = "?page=" + this.state.page;
        let params = {};

        params.sizePerPage = this.state.sizePerPage;
        params.sortOrder = this.state.sortOrder;
        params.sortField = this.state.sortField;
        params.filter = this.state.filter;

        query += this.implodeQuery(params);

        this.request(query, (response) => this.setState({
            data: response.data.data,
            totalSize: response.data.meta.total,
        }));
    }

    change(type, newState) {
        let query = "?page=" + newState.page;
        let params = {};

        params.sizePerPage = newState.sizePerPage;
        params.sortOrder = newState.sortOrder;
        params.sortField = newState.sortField;
        params.filter = this.state.filter;

        query += this.implodeQuery(params);

        this.request(query, (response) => this.setState({
            data: response.data.data,
            sizePerPage: newState.sizePerPage,
            page: newState.page ? newState.page : 1,
            sortOrder: newState.sortOrder,
            sortField: newState.sortField,
            totalSize: response.data.meta.total
        }));
    }

    filter(value) {
        this.setState({
            filter: value,
        });

        if(value.length === 0 || value.length >= 3) {
            let query = "?page=" + 1;
            let params = {};

            params.sizePerPage = this.state.sizePerPage;
            params.sortOrder = this.state.sortOrder;
            params.sortField = this.state.sortField;
            params.filter = value;

            query += this.implodeQuery(params);

            this.request(query, (response) => this.setState({
                page: 1,
                data: response.data.data,
                totalSize: response.data.meta.total,
            }));
        }
    }

    request(query, callback) {
        this.setState({
            loading: true,
        });

        this.axiosCancelSource = axios.CancelToken.source()

        if(this.props.extraQuery)
            query += this.props.extraQuery;

        axios.get(this.props.entrypoint + query, {cancelToken: this.axiosCancelSource.token}).then(response => {
            callback(response);

            if(this.props.onDataLoaded)
                this.props.onDataLoaded(response.data);

            this.setState({
                loading: false,
            });
        });
    }

    customTotal(from, to, size) {
        return (
            <span className="react-bootstrap-table-pagination-total">
                {this.props.intl.formatMessage({id: "Affichage_lignes"}, {from: from, to: to, size: size})}
            </span>
        );
    }

    implodeQuery(string) {
        let query = "";

        for (const [key, value] of Object.entries(string))
            if(value)
                query += "&" + key + "=" + value;

        return query
    }

    render() {
        /*persistor.store(this.props.id, "sizePerPage", this.state.sizePerPage);
        persistor.store(this.props.id, "page", this.state.page);
        persistor.store(this.props.id, "filter", this.state.filter);
        persistor.store(this.props.id, "sortOrder", this.state.sortOrder);
        persistor.store(this.props.id, "sortField", this.state.sortField);*/

        const options = {
            custom: true,
            page: this.state.page,
            totalSize: this.state.totalSize,
            sizePerPage: this.state.sizePerPage,
            paginationTotalRenderer: this.customTotal.bind(this),
            sizePerPageList: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "25", value: 25 },
                { text: "50", value: 50 }
            ],
        };

        let defaultSorted = null;

        if(this.state.sortField)
            defaultSorted = [{
                dataField: this.state.sortField,
                order: this.state.sortOrder
            }];

        return (
            <>
                <div className="form-group row">
                    <div className="col-lg-3">
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder={this.props.intl.formatMessage({id: 'Rechercher...'})}
                            value={this.state.filter}
                            onChange={(e) => this.filter(e.target.value)}
                        />
                    </div>
                    <div className="col-lg-9">
                        {this.props.header}
                    </div>
                </div>

                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={this.state.loading}
                                paginationProps={paginationProps}
                            >
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    bordered={false}
                                    remote
                                    keyField="id"
                                    data={this.state.data}
                                    columns={this.props.columns}
                                    defaultSorted={defaultSorted}
                                    onTableChange={ (type, newState) => this.change(type, newState) }
                                    { ...paginationTableProps }
                                >
                                    <PleaseWaitMessage entities={this.state.data}/>
                                    <NoRecordsFoundMessage entities={this.state.data}/>
                                </BootstrapTable>
                            </Pagination>
                        );
                    }}
                </PaginationProvider>
            </>
        );
    }
}

export default injectIntl(Table);
