import React, {useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Form} from "./Form";

export const Create = ({history, intl}) => {
    const suhbeader = useSubheader();
    const [loading, setLoading] = useState(false);

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Ajouter un nouveau client"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Ajouter un nouveau client"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => history.push("/customers")}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left" />
                        {intl.formatMessage({id: "Retour"})}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => handleSubmit()}
                    >
                        {intl.formatMessage({id: "Enregistrer"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Form method={"POST"} setHandleSubmit={(func) => {handleSubmit = func}} loading={setLoading} history={history} intl={intl} />
            </CardBody>
        </Card>
    );
};
