import React, {useContext} from "react";
import { Formik, Form as Formular, Field } from "formik";
import axios from "axios";
import {Input} from "../../components/form/Input";
// eslint-disable-next-line no-restricted-imports
import Tabs from '@material-ui/core/Tabs';
// eslint-disable-next-line no-restricted-imports
import Tab from '@material-ui/core/Tab';
// eslint-disable-next-line no-restricted-imports
import Table from '@material-ui/core/Table';
// eslint-disable-next-line no-restricted-imports
import TableBody from '@material-ui/core/TableBody';
// eslint-disable-next-line no-restricted-imports
import TableCell from '@material-ui/core/TableCell';
// eslint-disable-next-line no-restricted-imports
import TableHead from '@material-ui/core/TableHead';
// eslint-disable-next-line no-restricted-imports
import TableRow from '@material-ui/core/TableRow';
// eslint-disable-next-line no-restricted-imports
import Checkbox from "@material-ui/core/Checkbox";
import {ApplicationContext} from "../../library/ApplicationContext";
import {utils} from "../../library/Utils";
import {Languages} from "../../library/Enums/Languages";
import {Gateways} from "../../library/Enums/Gateways";

export const Form = ({method, data, setHandleSubmit, loading, history, intl}) => {
    if(!data)
        data = {
            name: "",
            phone: "",
            logo: "",
            language: Languages.FRENCH,
            reports: false,
            reportsDelay: "",
            reportsEmails: "",
            gateways: [],
            modules: [],
            custom_fields: [],
            status: true,
        };

    const [tab, setTab] = React.useState(0);
    const [modulesRoles, setModulesRoles] = React.useState([]);

    if(modulesRoles.length === 0) {
        data.modules.forEach(data => {
            if(!modulesRoles[data.pivot.module])
                modulesRoles[data.pivot.module] = [];

            modulesRoles[data.pivot.module][data.pivot.role] = true;
        });
    }

    const save = (values, setErrors) => {
        loading(true);

        let url = "";

        if(data.id)
            url = "api/customers/" + data.id;
        else
            url = "api/customers";

        values.modules = modulesRoles;

        axios({method: "POST", url: url, data: utils.objectToFormData(values, method)}).then(response => {
            loading(false);

            history.push('/customers');
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            loading(false);
        });
    };

    const isModulesRolesChecked = (module, role) => {
        return !!(modulesRoles[module] && modulesRoles[module][role]);
    };

    const toggleModulesRoles = (module, role) => {
        if(modulesRoles[module] && modulesRoles[module][role])
            delete modulesRoles[module][role];
        else {
            if (!modulesRoles[module])
                modulesRoles[module] = [];

            modulesRoles[module][role] = true;
        }

        setModulesRoles([...modulesRoles]);
    }

    const { application } = useContext(ApplicationContext);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={(values, { setErrors }) => {
                save(values, setErrors);
            }}
        >
            {props => {
                setHandleSubmit(props.handleSubmit);

                const addCustomField = () => {
                    const customFields = [...props.values['custom_fields']];

                    customFields.push({name: ""});

                    props.setFieldValue('custom_fields', customFields);
                };

                const removeCustomField = (index) => {
                    props.values['custom_fields'].splice(index, 1);

                    props.setFieldValue('custom_fields', props.values['custom_fields']);
                };

                return (
                    <Formular className="form form-label-right">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-12 offset-0">
                                <Tabs
                                    value={tab}
                                    onChange={(event, newValue) => setTab(newValue)}
                                >
                                    <Tab label={intl.formatMessage({id: "Informations sur le client"})} />
                                    <Tab label={intl.formatMessage({id: "Champs personnalisés"})} />
                                    <Tab label={intl.formatMessage({id: "Moyens d'alarme"})} />
                                    <Tab label={intl.formatMessage({id: "Permissions"})} />
                                </Tabs>
                                {tab === 0 &&
                                    <div style={{paddingTop: 20}}>
                                        <Field
                                            name="name"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Nom du client"})}
                                        />
                                        <Field
                                            name="phone"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Téléphone"})}
                                        />
                                        <Field
                                            name="language"
                                            component={Input}
                                            type={"select"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Langue des rapports et de l'application"})}
                                            data={[{
                                                value: 1,
                                                label: 'Français',
                                            },{
                                                value: 2,
                                                label: 'Deutsch',
                                            },{
                                                value: 3,
                                                label: 'English',
                                            },{
                                                value: 4,
                                                label: 'Italiano',
                                            }]}
                                        />
                                        <Field
                                            name="timeoutNotification"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Temps limite (en minutes) pour valider une notification (si vide, aucune limite)"})}
                                        />
                                        <Field
                                            name="logo"
                                            component={Input}
                                            type={"file"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Logo"})}
                                            {...props}
                                        />
                                        <Field
                                            name="reports"
                                            component={Input}
                                            type={"checkbox"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Envoi des rapports"})}
                                            {...props}
                                        />
                                        {(props.values.reports === true || props.values.reports === 1) &&
                                            <Field
                                                name="reportsDelay"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Délai d'envoi des rapports"})}
                                            />
                                        }
                                        {(props.values.reports === true || props.values.reports === 1) &&
                                            <Field
                                                name="reportsEmails"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                placeholder={intl.formatMessage({id: "Adresses e-mails séparées par des ; Exemple: xxx@helpro.ch;yyy@helpro.ch"})}
                                                label={intl.formatMessage({id: "Rapports d'alarme"})}
                                            />
                                        }
                                        <Field
                                            name="sendGNToUserWithoutTracking"
                                            component={Input}
                                            type={"checkbox"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Envoyer les notifications geofencing aux utilisateurs avec le tracking désactivé?"})}
                                            {...props}
                                        />
                                        <Field
                                            name="deleteMessageAfterSending"
                                            component={Input}
                                            type={"checkbox"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Effacer les messages après l'envoi"})}
                                            {...props}
                                        />
                                        <Field
                                            name="status"
                                            component={Input}
                                            type={"checkbox"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Actif?"})}
                                            {...props}
                                        />
                                    </div>
                                }
                                {tab === 1 &&
                                    <div style={{paddingTop: 20}}>
                                        {props.values['custom_fields'].map((e, index) =>
                                            <div key={index}>
                                                <Field
                                                    name={`custom_fields.${index}.name`}
                                                    component={Input}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Nom"})}
                                                />
                                                <Field
                                                    parent="custom_fields"
                                                    index={index}
                                                    name="displayOnList"
                                                    component={Input}
                                                    type={"checkbox"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Affiché sur la liste?"})}
                                                    {...props}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm mb-20"
                                                    onClick={() => removeCustomField(index)}
                                                >
                                                    {intl.formatMessage({id: "Supprimer"})}
                                                </button>
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => addCustomField()}
                                        >
                                            {intl.formatMessage({id: "Ajouter un champ personnalisé"})}
                                        </button>
                                    </div>
                                }
                                {tab === 2 &&
                                    <div style={{paddingTop: 20}}>
                                        <Field
                                            name={"gateways"}
                                            component={Input}
                                            type={"checkbox-list"}
                                            label={data.name}
                                            data={application.catalog.gateways}
                                            {...props}
                                        />
                                    </div>
                                }
                                {tab === 3 &&
                                    <div style={{paddingTop: 20}}>
                                        <Table className={"mb-20 mt-0"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{intl.formatMessage({id: "Modules"})}</TableCell>
                                                    {application.catalog.roles.map(role =>
                                                        <TableCell key={role.id} align="right">{role.name}</TableCell>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {application.catalog.modules.map(module => (
                                                    <TableRow key={module.id}>
                                                        <TableCell component="th" scope="row">
                                                            {module.name}
                                                        </TableCell>
                                                        {application.catalog.roles.map(role =>
                                                            <TableCell key={role.id} align="right"><Checkbox onChange={() => toggleModulesRoles(module.id, role.id)} checked={isModulesRolesChecked(module.id, role.id)} /></TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </div>
                    </Formular>
                )}
            }
        </Formik>
    );
}
