import React, {useContext, useEffect, useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Form} from "./Form";
import axios from "axios";
import {utils} from "../../library/Utils";
import {ApplicationContext} from "../../library/ApplicationContext";

export const Update = ({history, intl, match: {params: { id }}}) => {
    const suhbeader = useSubheader();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState({groups: [], user: ''});
    const { application } = useContext(ApplicationContext);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const user = await axios("/api/users/" + id);
            const customFields = user.data.user_custom_fields;

            application.catalog.customFields.map((customField, index) => {
                    if (!user.data.user_custom_fields.map(e => e.id).includes(customField.id))
                        customFields.push({id: customField.id});
                }
            );

            user.custom_fields = customFields;

            let groups = await axios("/api/groups?sizePerPage=99999");

            groups = groups.data.data;

            if(groups)
                groups = groups.filter(group => !group.hasChildren);

            setLoading(false);
            setLoaded(true);

            setData({groups: groups, user: user.data});
        };

        if (!loaded && !loading) {
            getData();
        }
    });

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Mise à jour de l'utilisateur"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Mise à jour de l'utilisateur"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => history.push("/users")}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left" />
                        {intl.formatMessage({id: "Retour"})}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => handleSubmit()}
                    >
                        {intl.formatMessage({id: "Enregistrer"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Form method={"PUT"} data={data.user} groups={data.groups} setHandleSubmit={(func) => {handleSubmit = func}} loading={setLoading} history={history} intl={intl} />
            </CardBody>
        </Card>
    );
};
