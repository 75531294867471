import React, {Suspense} from "react";
import {Redirect, Switch} from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { Dashboard } from "./pages/Dashboard/Dashboard";

import {List as Customers} from "./pages/Customers/List";
import {Create as CustomersCreate} from "./pages/Customers/Create";
import {Update as CustomersUpdate} from "./pages/Customers/Update";

import {List as Users} from "./pages/Users/List";
import {Create as UsersCreate} from "./pages/Users/Create";
import {Update as UsersUpdate} from "./pages/Users/Update";
import {Import as UsersImport} from "./pages/Users/Import";
import {Profil} from "./pages/Users/Profil";

import {List as Roles} from "./pages/Roles/List";
import {Create as RolesCreate} from "./pages/Roles/Create";
import {Update as RolesUpdate} from "./pages/Roles/Update";

import {List as FlashMessages} from "./pages/FlashMessages/List";
import {Create as FlashMessagesCreate} from "./pages/FlashMessages/Create";
import {Update as FlashMessagesUpdate} from "./pages/FlashMessages/Update";

import {List as Messages} from "./pages/Messages/List";
import {Create as MessagesCreate} from "./pages/Messages/Create";
import {Update as MessagesUpdate} from "./pages/Messages/Update";

import {List as Groups} from "./pages/Groups/List";
import {Create as GroupsCreate} from "./pages/Groups/Create";
import {Update as GroupsUpdate} from "./pages/Groups/Update";

import {List as Templates} from "./pages/Templates/List";
import {Create as TemplatesCreate} from "./pages/Templates/Create";
import {Update as TemplatesUpdate} from "./pages/Templates/Update";

import {List as Notifications} from "./pages/Notifications/List";

import {List as Logs} from "./pages/Logs/List";

import {List as Changelog} from "./pages/Changelog/List";

import {List as History} from "./pages/History/List";

import {List as Credits} from "./pages/Credits/List";
import ContentRoute from "./components/content/ContentRoute";


export default function ApplicationRoutes() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={Dashboard} />

                {/* customers */}
                <ContentRoute exact path="/customers" component={Customers} slug="customers" />
                <ContentRoute path="/customers/create" component={CustomersCreate} slug="customers" />
                <ContentRoute path="/customers/:id/update" component={CustomersUpdate} slug="customers" />

                {/* users */}
                <ContentRoute exact path="/users" component={Users} slug="users" />
                <ContentRoute path="/users/create" component={UsersCreate} slug="users" />
                <ContentRoute path="/users/:id/update" component={UsersUpdate} slug="users" />
                <ContentRoute path="/users/import" component={UsersImport} slug="users" />

                {/* roles */}
                <ContentRoute exact path="/roles" component={Roles} slug="roles" />
                <ContentRoute path="/roles/create" component={RolesCreate} slug="roles" />
                <ContentRoute path="/roles/:id/update" component={RolesUpdate} slug="roles" />

                {/* flashMessages */}
                <ContentRoute exact path="/flashMessages" component={FlashMessages} slug="flashMessages" />
                <ContentRoute path="/flashMessages/create" component={FlashMessagesCreate} slug="flashMessages" />
                <ContentRoute path="/flashMessages/:id/update" component={FlashMessagesUpdate} slug="flashMessages" />

                {/* messages */}
                <ContentRoute exact path="/messages" component={Messages} slug="messages" />
                <ContentRoute path="/messages/create" component={MessagesCreate} slug="messages" />
                <ContentRoute path="/messages/:id/update" component={MessagesUpdate} slug="messages" />

                {/* groups */}
                <ContentRoute exact path="/groups" component={Groups} slug="groups" />
                <ContentRoute path="/groups/create" component={GroupsCreate} slug="groups" />
                <ContentRoute path="/groups/:id/update" component={GroupsUpdate} slug="groups" />

                {/* templates */}
                <ContentRoute exact path="/templates" component={Templates} slug="templates" />
                <ContentRoute path="/templates/create" component={TemplatesCreate} slug="templates" />
                <ContentRoute path="/templates/:id/update" component={TemplatesUpdate} slug="templates" />

                {/* notifications */}
                <ContentRoute exact path="/notifications/" component={Notifications} slug="notifications" />
                <ContentRoute exact path="/geofencing" component={Notifications} slug="geofencing" />
                <ContentRoute exact path="/template-notifications/:id" component={Notifications} slug="template-notifications" />

                {/* profil */}
                <ContentRoute exact path="/profile" component={Profil} />

                {/* logs */}
                <ContentRoute exact path="/logs" component={Logs} slug="logs" />

                {/* changelog */}
                <ContentRoute exact path="/changelog" component={Changelog} slug="changelog" />

                {/* history */}
                <ContentRoute exact path="/history" component={History} slug="history" />

                {/* credits */}
                <ContentRoute exact path="/credits" component={Credits} slug="credits" />
            </Switch>
        </Suspense>
    );
}
