import React, {useState} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";
import {
    sortCaret, toAbsoluteUrl
} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";

export const List = ({intl}) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({id: "Journal de l'application"}));

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [log, setLog] = useState({});

    const columns = [
        {
            dataField: 'date',
            text: intl.formatMessage({id: "Date"}),
            sort: true,
            sortCaret: sortCaret,
            style: {
                minWidth: "150px",
            },
        }, {
            dataField: 'user',
            text: intl.formatMessage({id: "Utilisateur"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'url',
            text: intl.formatMessage({id: "URL"}),
            sort: true,
            sortCaret: sortCaret,
            formatter: (value) => value.length > 50 ? value.substr(0, 50) + "..." : value
        }, {
            dataField: 'line',
            text: intl.formatMessage({id: "Ligne"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'message',
            text: intl.formatMessage({id: "Message"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: "action",
            text: intl.formatMessage({id: "Actions"}),
            formatter: (
                cellContent,
                row,
            ) => <OverlayTrigger
                overlay={<Tooltip id="products-delete-tooltip">{intl.formatMessage({id: "Voir le détail"})}</Tooltip>}
                    >
                            <a
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={() => {setShowDetailsModal(true); setLog(row); }}
                            >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
                    </span>
                </a>
            </OverlayTrigger>,
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: "Journal de l'application"})}>

            </CardHeader>
            <CardBody>
                <Table id={"logs-list"} columns={columns} entrypoint={"api/logs"} />
                <Modal
                    size="xl"
                    show={showDetailsModal}
                    aria-labelledby="modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-lg">
                            {intl.formatMessage({id: "Détail"})}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span><strong>{intl.formatMessage({id: "Date"})}: </strong>{log.date}</span><br />
                        <span><strong>{intl.formatMessage({id: "URL"})}: </strong>{log.url}</span><br />
                        <span><strong>{intl.formatMessage({id: "Fichier"})}: </strong>{log.file}</span><br />
                        <span><strong>{intl.formatMessage({id: "Ligne"})}: </strong>{log.line}</span><br />
                        <span><strong>{intl.formatMessage({id: "Message"})}: </strong>{log.message}</span><br />
                        <span><strong>{intl.formatMessage({id: "Trace"})}: </strong>{log.trace}</span><br />
                        <span><strong>{intl.formatMessage({id: "Date"})}: </strong><pre>{log.data}</pre></span>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button
                                type="button"
                                onClick={() => setShowDetailsModal(false)}
                                className="btn btn-light btn-elevate"
                            >
                                Ok
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </CardBody>
        </Card>
    );
};
