import React, {useState, useEffect } from "react";
import {NotificationStatus} from "../../library/Enums/NotificationStatus";
import {sortCaret} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {Markers} from "./Markers";

export const Detail = ({intl, notification, notificationUserGateway}) => {
    const columnsRecipients = [
        {
            dataField: 'user.id',
            text: intl.formatMessage({id: "Confirmé?"}),
            sort: false,
            hidden: !notification.acknowlegeable,
            formatter: (value, row) => {
                if(row.related.filter(related =>
                    related.status === NotificationStatus.ACKNOWLEDGED
                ).length === 0)
                    return <span className={`label label-md label-light-warning label-inline`}>{intl.formatMessage({id: "En attente"})}</span>;

                if(row.related.filter(related =>
                    related.acknowlegedStatus === 1
                ).length > 0)
                    return <span className={`label label-md label-light-success label-inline`}>{intl.formatMessage({id: "Oui"})}</span>;

                return <span className={`label label-md label-light-danger label-inline`}>{intl.formatMessage({id: "Non"})}</span>;
            }
        },
        {
            dataField: 'user.fullname',
            text: intl.formatMessage({id: "Envoyé à"}),
            sort: true,
            sortCaret: sortCaret
        },
        {
            dataField: 'user_gateway.gateway.typeLabel',
            text: intl.formatMessage({id: "Moyen d'alarme"}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) => {
                    if(related.virtual_gateway != null)
                        return <div key={key} style={styles.recipient}>{related.virtual_gateway.typeLabel}</div>
                    else
                        return <div key={key} style={styles.recipient}>{related.user_gateway.gateway.typeLabel}</div>
                });
            }
        },
        {
            dataField: 'sentAt',
            text: intl.formatMessage({id: "Envoyé le"}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) =>
                    <div key={key} style={styles.recipient}>{related.sentAt}</div>
                );
            }
        },{
            dataField: 'deliveredAt',
            text: intl.formatMessage({id: "Reçu le"}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) =>
                    <div key={key} style={styles.recipient}>{related.deliveredAt}</div>
                );
            }
        },{
            dataField: 'acknowlegedAt',
            text: intl.formatMessage({id: "Confirmé le"}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) =>
                    <div key={key} style={styles.recipient}>{related.acknowlegedAt}</div>
                );
            }
        },{
            dataField: 'acknowlegedMessage',
            text: intl.formatMessage({id: "Message conf."}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) =>
                    <div key={key} style={styles.recipient}>{related.acknowlegedMessage}</div>
                );
            }
        },{
            dataField: 'statusLabel',
            text: intl.formatMessage({id: "Status"}),
            sort: false,
            formatter: (value, row) => {
                return row.related.map((related, key) =>
                    <div key={key} style={styles.recipient}><span className={`label label-md label-light-${related.statusClass} label-inline`}>{related.statusLabel}</span></div>
                );
            }
        }
    ];

    const styles = {
        recipient: {
            fontSize: '12px',
            height: '23px',
            marginTop: '2px',
            marginBottom: '2px',
        },
        map: {
            width: '100%',
            height: '400px',
            borderRadius: 5,
            marginTop: 30,
            marginBottom: 20,
        }
    };

    const center = {
        lat: 46.800663464,
        lng: 8.222665776,
    };

    const [showMap, setShowMap] = useState(false);
    const [initPoints, setInitPoints] = useState([]);

    let points = [];

    const parseMapData = (data) => {
        let map = false;

        points = [];

        data.data.map(function(e) {
            e.related.map(function(e) {
                if (e.latitude && (e.status === NotificationStatus.SENT || e.status === NotificationStatus.DELIVERED || e.status === NotificationStatus.ACKNOWLEDGED)) {
                    map = true;

                    points.push({id: e.id, fullname: e.user.fullname, latitude: e.latitude, longitude: e.longitude, acknowlegedStatus: e.acknowlegedStatus});
                }
            });
        });

        if(showMap !== map) {
            setInitPoints(points);
            setShowMap(map);
        }
    };

    return(
        <>
            <Table id={"history-notification-recipients-" + notification.id} columns={columnsRecipients} entrypoint={"api/notifications/history/" + notification.id} refreshAuto={true} preservePagination={false} onDataLoaded={(data) => parseMapData(data)} />

            {showMap && (
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                    <GoogleMap mapContainerStyle={styles.map} center={center} zoom={7.5} options={{controlSize: 25}}>
                        {notification.latitude && notification.longitude && (
                            <Marker
                                position={{lat: notification.latitude, lng: notification.longitude}}
                                icon={{
                                    path: 3,
                                    strokeColor: "blue",
                                    scale: 5
                                }}
                            />
                        )}

                        <Markers points={initPoints} getPoints={() => points} />
                    </GoogleMap>
                </LoadScript>
            )}
        </>
    );
}
