export class utils {
    static objectToFormData(object, method) {
        let formData = new FormData();
        formData.append('_method', method);

        Object.keys(object).forEach(function(key) {
            let value = object[key];

            if(value == null)
                value = "";
            else if(typeof value == "boolean")
                value = value ? 1 : 0;
            else if(value instanceof Array)
                value = JSON.stringify(value);

            formData.append(key, value);
        });

        return formData;
    }

    static canAccess = (slug, application) => {
        if(application.user.customer.modules.filter(e => e.slug === slug && e.pivot.role === application.user.role).length === 0)
            return false;
        else
            return true;
    }
}
