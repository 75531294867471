import React, {useContext} from "react";
import {Route} from "react-router-dom";
import {Content} from "../../../_metronic/layout";
import {ApplicationContext} from "../../library/ApplicationContext";
import {utils} from "../../library/Utils";
import {injectIntl} from "react-intl";

function ContentRoute({ children, component, render, ...props }) {
    const { application } = useContext(ApplicationContext);

    return (
        <Route {...props}>
            {routeProps => {
                routeProps.intl = props.intl;

                if(props.slug == null || utils.canAccess(props.slug, application)) {
                    if (typeof children === "function") {
                        return <Content>{children(routeProps)}</Content>;
                    }

                    if (!routeProps.match) {
                        return null;
                    }

                    if (children) {
                        return <Content>{children}</Content>;
                    }

                    if (component) {
                        return (
                            <Content>{React.createElement(component, routeProps)}</Content>
                        );
                    }

                    if (render) {
                        return <Content>{render(routeProps)}</Content>;
                    }
                }

                return null;
            }}
        </Route>
    );
}

export default injectIntl(ContentRoute);
