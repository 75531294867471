import React, {useContext, useEffect, useState} from "react";
import { Formik, Form as Formular, Field } from "formik";
import axios from "axios";
import {Input} from "../../components/form/Input";
import {utils} from "../../library/Utils";
import {ApplicationContext} from "../../library/ApplicationContext";
import {FormLabel} from "@material-ui/core";

export const Form = ({method, data, setHandleSubmit, loading, history, intl}) => {
    if(!data)
        data = {
            customer: "",
            type: "",
            name: "",
            groups: [],
            gateways: [],
            users: [],
        };

    const [customers, setCustomers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [type, setType] = useState(0);

    const save = (values, setErrors) => {
        loading(true);

        let url = "";

        if(data.id)
            url = "api/templates/" + data.id;
        else
            url = "api/templates";

        axios({method: "POST", url: url, data: utils.objectToFormData(values, method)}).then(response => {
            loading(false);

            history.push('/templates');
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            loading(false);
        });
    };

    const selectType = (e, setFieldValue) => {
        setFieldValue("type", e.target.value);
        setType(e.target.value);
    }

    useEffect(() => {
        const getData = async () => {
            const customers = await axios("/api/customers");
            const groups = await axios("api/groups?all=true&sizePerPage=99999");
            const users = await axios("api/users?all=true&sizePerPage=99999");

            setCustomers(customers.data.data);
            setGroups(groups.data.data);
            setUsers(users.data.data);
        };

        if (groups.length === 0) {
            getData();
        }
    }, []);

    const { application } = useContext(ApplicationContext);

    if(data.customer === "" && customers.length > 0)
        data.customer = customers[0].id;

    if(data.customer === "")
        return <></>
    else
        return (
            <Formik
                enableReinitialize={true}
                initialValues={data}
                onSubmit={(values, { setErrors }) => {
                    save(values, setErrors);
                }}
            >
                {props => {
                    setHandleSubmit(props.handleSubmit);

                    const isInSelectedGroup = (user) => {
                        let groups = props.values["groups"];

                        if(user.groups.some(group => groups.map(g => g.id).includes(group.id)))
                            return true;
                        else
                            return false;
                    };

                    return (
                        <Formular className="form form-label-right">
                            <div className="row">
                                <div className="col-md-8 offset-md-2 col-12 offset-0 mb-20">
                                    <Field
                                        name="customer"
                                        component={Input}
                                        type={"select"}
                                        withFeedbackLabel={true}
                                        label={intl.formatMessage({id: "Clients"})}
                                        data={customers}
                                        valueField={"id"}
                                        labelField={"name"}
                                    />
                                    <Field
                                        name="type"
                                        component={Input}
                                        type={"select"}
                                        withFeedbackLabel={true}
                                        label={intl.formatMessage({id: "Type"})}
                                        data={application.catalog.templateTypes}
                                        onChange={(e) => selectType(e, props.setFieldValue)}
                                        valueField={"id"}
                                        labelField={"name"}
                                    />
                                    <Field
                                        name="name"
                                        component={Input}
                                        withFeedbackLabel={true}
                                        label={intl.formatMessage({id: "Nom"})}
                                    />

                                    <div className={"row"}>
                                        {(props.values.type === 1 || props.values.type === 2 || props.values.type === 4 || props.values.type === 6) && (
                                            <div className={"col-lg-4"}>
                                                <FormLabel component="legend">{intl.formatMessage({id: "Groupes"})}</FormLabel>
                                                <Field
                                                    name="groups"
                                                    component={Input}
                                                    type={"checkbox-list"}
                                                    withFeedbackLabel={true}
                                                    data={groups.filter(e => e.customer === props.values.customer)}
                                                    inline={false}
                                                    {...props}
                                                />
                                            </div>
                                        )}

                                        {(props.values.type === 1 || props.values.type === 2 || props.values.type === 5 || props.values.type === 6) && (
                                            <div className={"col-lg-4"}>
                                                <FormLabel component="legend">{intl.formatMessage({id: "Passerelles"})}</FormLabel>
                                                <Field
                                                    name="gateways"
                                                    component={Input}
                                                    type={"checkbox-list"}
                                                    withFeedbackLabel={true}
                                                    data={application.catalog.gateways}
                                                    inline={false}
                                                    {...props}
                                                />
                                            </div>
                                        )}

                                        {(props.values.type === 3 || props.values.type === 5) && (
                                            <div className={"col-lg-4"}>
                                                <FormLabel component="legend">{intl.formatMessage({id: "Groupes parents"})}</FormLabel>
                                                <Field
                                                    name="groups"
                                                    component={Input}
                                                    type={"checkbox-list"}
                                                    withFeedbackLabel={true}
                                                    data={groups.filter(e => (e.parent === null && e.customer === props.values.customer))}
                                                    inline={false}
                                                    {...props}
                                                />
                                            </div>
                                        )}

                                        {props.values.type === 4 && (
                                            <div className={"col-lg-4"}>
                                                <FormLabel component="legend">{intl.formatMessage({id: "Utilisateurs"})}</FormLabel>
                                                <Field
                                                    name="users"
                                                    component={Input}
                                                    type={"checkbox-list"}
                                                    withFeedbackLabel={true}
                                                    data={users.filter(e => (e.customer.id === props.values.customer && e.status === 1 && isInSelectedGroup(e)))}
                                                    inline={false}
                                                    {...props}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Formular>
                    )}
                }
            </Formik>
        );
}
