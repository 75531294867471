import React, {useContext, useEffect, useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import {utils} from "../../library/Utils";
import { Formik, Form as Formular, Field } from "formik";
import {Input} from "../../components/form/Input";
import {Alert} from "react-bootstrap";
import {ApplicationContext} from "../../library/ApplicationContext";

export const Profil = ({history, intl, match: {params: { id }}}) => {
    const suhbeader = useSubheader();
    const { application } = useContext(ApplicationContext);
    const [updated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({role: "",
        id: "",
        name: "",
        firstname: "",
        email: "",
        password: "",
        password_confirmation: "",
        function: "",
        language: "",
        status: ""}
    );

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            const user = await axios("/api/profil");

            setLoading(false);

            setData(user.data);
        };

        if (data.id === "" && !loading) {
            getData();
        }
    });

    const save = (values, setErrors) => {
        setLoading(true);
        setUpdated(false);

        axios({method: "POST", url: 'api/profil', data: utils.objectToFormData(values, 'POST')}).then(response => {
            setLoading(false);
            setUpdated(true);

            application.updateLanguage(response.data);
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            setLoading(false);
        });
    };

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Mise à jour du profil"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Mise à jour du profil"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => handleSubmit()}
                    >
                        {intl.formatMessage({id: "Enregistrer"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Formik
                    enableReinitialize={true}
                    initialValues={data}
                    onSubmit={(values, { setErrors }) => {
                        save(values, setErrors);
                    }}
                >
                    {props => {
                        handleSubmit = props.handleSubmit

                        return (
                            <Formular className="form form-label-right">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2 col-12 offset-0">
                                        <div style={{paddingTop: 20}}>
                                            {updated && (
                                                <Alert variant={"success"} style={{marginTop: "11px"}}>
                                                    {intl.formatMessage({id: "Profil mis à jour"})}
                                                </Alert>
                                            )}
                                            <Field
                                                name="name"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Nom"})}
                                            />
                                            <Field
                                                name="firstname"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Prénom"})}
                                            />
                                            <Field
                                                name="function"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Fonction"})}
                                            />
                                            <Field
                                                name="email"
                                                component={Input}
                                                withFeedbackLabel={true}
                                                disabled
                                                label={intl.formatMessage({id: "E-Mail"})}
                                            />
                                            <Field
                                                name="password"
                                                component={Input}
                                                type={"password"}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Mot de passe"})}
                                            />
                                            <Field
                                                name="password_confirmation"
                                                component={Input}
                                                type={"password"}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Mot de passe, confirmation"})}
                                            />
                                            <Field
                                                name="language"
                                                component={Input}
                                                type={"select"}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Langue des rapports et de l'application"})}
                                                data={[{
                                                    value: 1,
                                                    label: 'Français',
                                                },{
                                                    value: 2,
                                                    label: 'Deutsch',
                                                },{
                                                    value: 3,
                                                    label: 'English',
                                                },{
                                                    value: 4,
                                                    label: 'Italiano',
                                                }]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Formular>
                        )}
                    }
                </Formik>
            </CardBody>
        </Card>
    );
};
