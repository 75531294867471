import React, {useContext} from "react";
import { Formik, Form as Formular, Field } from "formik";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import TextField from '@material-ui/core/TextField';
import {Input} from "../../components/form/Input";
// eslint-disable-next-line no-restricted-imports
import Tabs from '@material-ui/core/Tabs';
// eslint-disable-next-line no-restricted-imports
import Tab from '@material-ui/core/Tab';
import {ApplicationContext} from "../../library/ApplicationContext";
import {utils} from "../../library/Utils";
import {Gateways} from "../../library/Enums/Gateways";
import {Languages} from "../../library/Enums/Languages";
import {sortCaret} from "../../../_metronic/_helpers";

export const Form = ({method, data, groups, setHandleSubmit, loading, history, intl}) => {
    const { application } = useContext(ApplicationContext);

    if(!data) {
        let customFields = [];

        application.catalog.customFields.map((customField, index) =>
            customFields.push({id : customField.id})
        );

        data = {
            id: "",
            role: "",
            name: "",
            firstname: "",
            email: "",
            password: "",
            function: "",
            language: Languages.FRENCH,
            status: 1,
            user_gateways: [],
            user_custom_fields: customFields,
            groups: [],
        };
    }

    const [tab, setTab] = React.useState(0);

    const save = (values, setErrors) => {
        loading(true);

        let url = "";

        if(data.id)
            url = "api/users/" + data.id;
        else
            url = "api/users";

        axios({method: method, url: url, data: values}).then(response => {
            loading(false);

            history.push('/users');
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            loading(false);
        });
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={(values, { setErrors }) => {
                save(values, setErrors);
            }}
        >
            {props => {
                setHandleSubmit(props.handleSubmit);

                const addGateway = () => {
                    const userGateways = [...props.values['user_gateways']];

                    userGateways.push({gateway: application.catalog.gateways.filter(e => (e.type === Gateways.CALL || e.type === Gateways.SMS))[0], recipient: "", status: 1});

                    props.setFieldValue('user_gateways', userGateways);
                };

                const removeGateway = (index) => {
                    props.values['user_gateways'].splice(index, 1);

                    props.setFieldValue('user_gateways', props.values['user_gateways']);
                };

                return (
                    <Formular className="form form-label-right">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-12 offset-0">
                                <Tabs
                                    value={tab}
                                    onChange={(event, newValue) => setTab(newValue)}
                                >
                                    <Tab label={intl.formatMessage({id: "Informations sur l'utilisateur"})} />
                                    <Tab label={intl.formatMessage({id: "Communication"})} />
                                    <Tab label={intl.formatMessage({id: "Groupes"})} />
                                </Tabs>
                                {tab === 0 &&
                                    <div style={{paddingTop: 20}}>
                                        <Field
                                            name="role"
                                            component={Input}
                                            type={"select"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Role"})}
                                            data={application.catalog.roles}
                                            valueField={"id"}
                                            labelField={"name"}
                                        />
                                        <Field
                                            name="name"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Nom"})}
                                        />
                                        <Field
                                            name="firstname"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Prénom"})}
                                        />
                                        <Field
                                            name="function"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Fonction"})}
                                        />

                                        {application.catalog.customFields.map((customField, index) =>
                                            <Field
                                                key={customField.id}
                                                name={`user_custom_fields.${index}.pivot.value`}
                                                component={Input}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: customField.name})}
                                            />
                                        )}

                                        <Field
                                            name="email"
                                            component={Input}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "E-Mail (si vide, une adresse email sera générée)"})}
                                        />
                                        <Field
                                            name="language"
                                            component={Input}
                                            type={"select"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Langue des rapports et de l'application"})}
                                            data={[{
                                                value: Languages.FRENCH,
                                                label: 'Français',
                                            },{
                                                value: Languages.GERMAN,
                                                label: 'Deutsch',
                                            },{
                                                value: Languages.ENGLISH,
                                                label: 'English',
                                            },{
                                                value: Languages.ITALIAN,
                                                label: 'Italiano',
                                            }]}
                                        />
                                        <Field
                                            name="password"
                                            component={Input}
                                            type={"password"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Mot de passe"})}
                                        />
                                        {data.id === "" && (
                                            <Field
                                                name="generateAndSend"
                                                component={Input}
                                                type={"checkbox"}
                                                withFeedbackLabel={true}
                                                label={intl.formatMessage({id: "Si mot de passe vide, le générer et l'envoyer par e-mail?"})}
                                                {...props}
                                            />
                                        )}
                                        <Field
                                            name="status"
                                            component={Input}
                                            type={"switch"}
                                            withFeedbackLabel={true}
                                            label={intl.formatMessage({id: "Inactif/Actif"})}
                                            {...props}
                                        />
                                    </div>
                                }
                                {tab === 1 &&
                                    <div style={{paddingTop: 20}}>
                                        {props.values['user_gateways'].map((e, index) =>
                                            <div key={index}>
                                                <Field
                                                    name={`user_gateways.${index}.gateway.id`}
                                                    component={Input}
                                                    type={"select"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Passerelle"})}
                                                    data={application.catalog.gateways.filter(e => e.type !== Gateways.SMARTPHONE)}
                                                    valueField={"id"}
                                                    labelField={"name"}
                                                />
                                                <Field
                                                    name={`user_gateways.${index}.recipient`}
                                                    component={Input}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Destinataire (Mobile, e-mail, pager, etc.)"})}
                                                />
                                                <Field
                                                    parent="user_gateways"
                                                    index={index}
                                                    name="status"
                                                    component={Input}
                                                    type={"checkbox"}
                                                    withFeedbackLabel={true}
                                                    label={intl.formatMessage({id: "Actif?"})}
                                                    {...props}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm mb-20"
                                                    onClick={() => removeGateway(index)}
                                                >
                                                    {intl.formatMessage({id: "Supprimer"})}
                                                </button>
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => addGateway()}
                                        >
                                            {intl.formatMessage({id: "Ajouter un moyen"})}
                                        </button>
                                    </div>
                                }
                                {tab === 2 &&
                                <div style={{paddingTop: 20, display: "flex", flexDirection: "column", flexWrap: "wrap", width: 330, maxHeight: 1000}}>
                                    <Field
                                        name={"groups"}
                                        component={Input}
                                        type={"checkbox-list"}
                                        label={data.name}
                                        data={groups}
                                        inline={false}
                                        {...props}
                                    />
                                </div>
                                }
                            </div>
                        </div>
                    </Formular>
                )}
            }
        </Formik>
    );
}
