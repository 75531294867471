/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext, useMemo} from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_metronic/layout/_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {ApplicationContext} from "../../library/ApplicationContext";
import {utils} from "../../library/Utils";
import axios from "axios";
import {SwitchUser} from "../SwitchUser";
import { useIntl } from "react-intl";

export function UserProfileDropdown() {
    const { user } = useSelector((state) => state.auth);
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            light:
                objectPath.get(uiService.config, "extras.user.dropdown.style") ===
                "light",
        };
    }, [uiService]);

    const { application } = useContext(ApplicationContext);

    const switchCustomer = customer => {
            axios("/api/customers/switch/" + customer).then(response => {

                const login = localStorage.getItem('persist:helpro-auth');

                localStorage.clear();

                localStorage.setItem('persist:helpro-auth', login)

                window.location.reload();
        });
    };

    const intl = useIntl();

    return (
        <Dropdown drop="down" alignRight>

            {utils.canAccess('customers', application) && (
                <Select value={application.user.currentCustomer} onChange={e => switchCustomer(e.target.value)} style={{marginRight: 40}}>
                    {application.catalog.customers.map(e =>
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    )}
                </Select>
            )}

            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-user-profile"
            >
                <div
                    className={
                        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    }
                >
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                        {user.fullname}
                    </span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
                <>
                    {!layoutProps.light && (
                        <div
                            className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/misc/bg-1.jpg"
                                )})`,
                            }}
                        >
                            <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                                {user.fullname}
                            </div>
                        </div>
                    )}
                </>

                <div className="navi navi-spacer-x-0 pt-5">
                    <Link to="/profile" className="navi-item px-8 cursor-pointer">
                        <div className="navi-link">
                            <div className="navi-icon mr-2">
                                <i className="flaticon2-calendar-3 text-success" />
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold cursor-pointer">
                                    {intl.formatMessage({id: "Mon Profil"})}
                                </div>
                                <div className="text-muted">
                                    {intl.formatMessage({id: "Paramètres du compte"})}
                                </div>
                            </div>
                        </div>
                    </Link>

                    <div className="navi-separator mt-3"></div>

                    <div className="navi-footer  px-8 py-5">
                        <Link
                            to="/logout"
                            className="btn btn-light-primary font-weight-bold"
                        >
                            {intl.formatMessage({id: "Fermer la session"})}
                        </Link>
                        <SwitchUser showButton={true} />
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
