import React, {useEffect, useState} from "react";
import { Formik, Form as Formular, Field } from "formik";
import axios from "axios";
import {Input} from "../../components/form/Input";
import {utils} from "../../library/Utils";

export const Form = ({method, data, setHandleSubmit, loading, history, intl}) => {
    if(!data)
        data = {
            parent: "",
            name: "",
        };

    const [roles, setRoles] = useState([]);

    const save = (values, setErrors) => {
        loading(true);

        let url = "";

        if(data.id)
            url = "api/roles/" + data.id;
        else
            url = "api/roles";

        axios({method: "POST", url: url, data: utils.objectToFormData(values, method)}).then(response => {
            loading(false);

            history.push('/roles');
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            loading(false);
        });
    };

    useEffect(() => {
        const getRoles = async () => {
            const roles = await axios("api/roles");

            setRoles(roles.data.data);
        };

        if (roles.length === 0) {
            getRoles();
        }
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={(values, { setErrors }) => {
                save(values, setErrors);
            }}
        >
            {props => {
                setHandleSubmit(props.handleSubmit);

                return (
                    <Formular className="form form-label-right">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-12 offset-0 mb-20">

                                <Field
                                    name="name"
                                    component={Input}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Nom"})}
                                />
                            </div>
                        </div>
                    </Formular>
                )}
            }
        </Formik>
    );
}
