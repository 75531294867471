import React, {useState} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
    sortCaret
} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";
import * as columnFormatters from "../../components/table/column-formatters";
import DeleteModal from "../../components/table/DeleteModal";
import axios from "axios";

export const List = ({ history, intl}) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({id: "Liste des messages"}));

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [id, setId] = useState(0);

    const columns = [
        {
            dataField: 'name',
            text: intl.formatMessage({id: "Nom"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: "action",
            text: intl.formatMessage({id: "Actions"}),
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                intl: intl,
                openEditPage: id => history.push("/messages/" + id + "/update"),
                openDeleteDialog: id => openDeleteDialog(id),
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    const create = () => {
        history.push("/messages/create");
    };

    const openDeleteDialog = (value) => {
        setShowDeleteModal(true);
        setId(value);
    };

    const confirmDelete = () => {
        setDeleting(true);

        axios.delete("api/messages/" + id).then(response => {
            setDeleting(false);
            setShowDeleteModal(false);
        });
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: "Liste des messages"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={create}
                    >
                        {intl.formatMessage({id: "Nouveau message"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Table id={"messages-list"} columns={columns} entrypoint={"api/messages"} deleting={deleting} />
                <DeleteModal show={showDeleteModal} deleting={deleting} confirmDelete={confirmDelete} cancelDelete={cancelDelete} />
            </CardBody>
        </Card>
    );
};
