import React from "react";

export class Thumb extends React.Component {
    state = {
        loading: false,
        preview: true,
        thumb: undefined,
    };

    componentDidMount() {
        if(this.props.file)
            this.display(this.props.file);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.file) { return; }

        if(prevProps.file !== this.props.file) {
            this.display(this.props.file);
        }
    }

    display(file) {
        this.setState({loading: true}, () => {
            if (file instanceof File)
                this.read(file, false);
            else
                this.read(process.env.REACT_APP_API_URL + "storage/" + file, true);
        });
    }

    async read(file, remote) {
        let blob;

        if(remote)
            blob = await fetch(file).then(r => r.blob());
        else
            blob = file;

        let reader = new FileReader();

        reader.onloadend = () => {
            if(reader.result.indexOf('image') === -1)
                this.setState({ loading: false, preview: false });
            else
                this.setState({ loading: false, preview: true, thumb: reader.result });
        };

        reader.readAsDataURL(blob);
    }

    render() {
        const { file } = this.props;
        const { loading, thumb, preview } = this.state;

        if (!file) { return null; }

        if (loading) { return <p>loading...</p>; }

        if (!preview) { return "Fichier: " + file.name; }

        return (
            <img src={thumb}
                alt={file.name}
                className="img-thumbnail mt-2"
                height={200}
                width={200}
        />);
    }
}
