import React, {useEffect, useState} from "react";
import { Formik, Form as Formular, Field } from "formik";
import axios from "axios";
import {Input} from "../../components/form/Input";
import {utils} from "../../library/Utils";
import {SketchPicker} from "react-color";

export const Form = ({method, data, setHandleSubmit, loading, history, intl}) => {
    if(!data)
        data = {
            parent: "",
            name: "",
            description: "",
            initialMessage: "",
            color: "",
        };

    const [groups, setGroups] = useState([]);

    const save = (values, setErrors) => {
        loading(true);

        let url = "";

        if(data.id)
            url = "api/groups/" + data.id;
        else
            url = "api/groups";

        axios({method: "POST", url: url, data: utils.objectToFormData(values, method)}).then(response => {
            loading(false);

            history.push('/groups');
        }).catch(error => {
            if(error.response)
                setErrors(error.response.data.errors);

            loading(false);
        });
    };

    useEffect(() => {
        const getGroups = async () => {
            const groups = await axios("api/groups?sizePerPage=99999");

            setGroups(groups.data.data);
        };

        if (groups.length === 0) {
            getGroups();
        }
    }, []);



    return (
        <Formik
            enableReinitialize={true}
            initialValues={data}
            onSubmit={(values, { setErrors }) => {
                save(values, setErrors);
            }}
        >
            {props => {
                setHandleSubmit(props.handleSubmit);

                const handleChangeComplete = (color) => {
                    props.setFieldValue('color', color.hex);
                };

                let color = '#FFFFFF';

                if(props.values['color'] !== '' && props.values['color'] !== null)
                    color = props.values['color'];

                return (
                    <Formular className="form form-label-right">
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-12 offset-0 mb-20">
                                <Field
                                    name="parent"
                                    component={Input}
                                    type={"select"}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Groupe parent"})}
                                    data={groups}
                                    valueField={"id"}
                                    labelField={"name"}
                                />
                                <Field
                                    name="name"
                                    component={Input}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Nom"})}
                                />
                                <Field
                                    name="description"
                                    component={Input}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Description"})}
                                    multiline
                                    rows="2"
                                />
                                <Field
                                    name="initialMessage"
                                    component={Input}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Texte initial lors de l'alarme du groupe"})}
                                    multiline
                                    rows="4"
                                />
                                <Field
                                    name="color"
                                    component={Input}
                                    withFeedbackLabel={true}
                                    label={intl.formatMessage({id: "Couleur"})}
                                />
                                <SketchPicker
                                    color={color}
                                    onChangeComplete={ handleChangeComplete }
                                />
                            </div>
                        </div>
                    </Formular>
                )}
            }
        </Formik>
    );
}
