import React from "react";
import { useIntl } from "react-intl";

export function Contact({ className }) {
    const intl = useIntl();

    return (
        <div className={`card card-custom ${className}`}>
            {/* Header */}
            <div className="card-header border-0 pt-5">
                <h3 className="card-title font-weight-bolder">{intl.formatMessage({id: "Contact - Greenbear"})}</h3>
            </div>
            {/* Body */}
            <div className="card-body d-flex flex-column">
                <div className="flex-grow-1 text-center">
                    <h1 className={"pt-15"}><span className={"fa fa-phone"}></span> +41 (0) 27 539 19 11</h1>
                    <h3 className={"pt-10"}><span className={"fa fa-envelope"}></span> support@greenbear.ch</h3>
                    <p className="pt-15 text-center font-weight-normal font-size-lg pb-7">
                        Via Cantonale 60, 6574 Vira Gambarogno<br/>
                        {intl.formatMessage({id: "Lun au ven: 08:00 – 12:00 / 13:00 – 16:00"})}
                    </p>
                </div>
            </div>
        </div>
    );
}
