/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {useHtmlClassService} from "../../../_metronic/layout";
import {Dropdown} from "react-bootstrap";
import {DropdownCustomToggler} from "../../../_metronic/_partials/dropdowns";
import {Gateways} from "../../library/Enums/Gateways";
import axios from "axios";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";

export function Chart({className, data}) {
    const intl = useIntl();
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBasePrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.base.primary"
            ),
            colorsThemeLightPrimary: objectPath.get(
                uiService.config,
                "js.colors.theme.light.primary"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    const [loading, setLoading] = useState(false);
    const [statsGateways, setStatsGateways] = useState({"1" : 0, "2": 0, "3": 0, "4": 0});
    const [total, setTotal] = useState(0);
    const [chart, setChart] = useState();

    useEffect(() => {
        getData();

    }, [layoutProps]);

    const getData = async (year = null) => {
        setLoading(true);

        const data = await axios("/api/dashboard/graph" + (year ? "/" + year : ""));
        const element = document.getElementById("kt_stats_widget_12_chart");
        const options = getChartOption(layoutProps, data.data.gateways.values, data.data.max, intl);

        const apexChart = new ApexCharts(element, options);

        apexChart.render();

        setChart(apexChart);
        setStatsGateways(data.data.gateways.total);
        setTotal(data.data.total);
        setLoading(false);
    };

    const selectYear = (year) => {
        document.dispatchEvent(new MouseEvent('click'));

        chart.destroy();
        getData(year);
    }

    const years = () => {
        const rows = [];

        for(let year = 2021; year <= new Date().getFullYear() ; year++)
            rows.push(
                <li className="navi-item" key={year}>
                    <a href="#" className="navi-link" onClick={() => selectYear(year)}>
                        <span className="navi-icon"><span className={"fa fa-calendar-alt"}></span></span>
                        <span className="navi-text">{year}</span>
                    </a>
                </li>
            )

        return rows;
    }

    return (
        <>
            <div className={`card card-custom ${className}`}>
                {loading && <ModalProgressBar />}
                <div className="card-body d-flex flex-column p-0">
                    <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                        <span className="symbol symbol-circle d-flex flex-column symbol-50 symbol-light-primary mr-2">
                            <span className="text-dark-75 font-weight-bolder font-size-h3">
                                {total}
                            </span>
                            <span className="text-muted font-weight-bold mt-2">{intl.formatMessage({id: "Messages envoyés"})}</span>
                        </span>
                        <div className="text-right">
                            <div className="card-toolbar">
                                <Dropdown className="dropdown-inline" alignRight>
                                    <Dropdown.Toggle
                                        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                        variant="transparent"
                                        id="dropdown-toggle-top"
                                        as={DropdownCustomToggler}
                                    >
                                        <i className="ki ki-bold-more-hor" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                        <ul className="navi navi-hover py-5">
                                            {years()}
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div
                        id="kt_stats_widget_12_chart"
                        className="card-rounded-bottom"
                        style={{height: "200px"}}
                    ></div>
                </div>
            </div>
            <div className={`card card-custom ${className}`}>
                <div className="card-body d-flex flex-column p-0">
                    <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-40 symbol-light-primary mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                                        <span className={"fa fa-sms"} style={{fontSize: "20px"}}></span>
                                    </span>
                                </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    {intl.formatMessage({id: "SMS"})}
                                </a>
                                <span className="text-muted">{statsGateways[2]} {intl.formatMessage({id: "transmis"})}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-40 symbol-light-warning mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-warning">
                                        <span className={"fa fa-phone"} style={{fontSize: "20px"}}></span>
                                    </span>
                                </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    {intl.formatMessage({id: "Appels"})}
                                </a>
                                <span className="text-muted">{statsGateways[1]} {intl.formatMessage({id: "transmis"})}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-40 symbol-light-success mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-success">
                                        <span className={"fa fa-mobile"} style={{fontSize: "20px"}}></span>
                                    </span>
                                </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    {intl.formatMessage({id: "Application"})}
                                </a>
                                <span className="text-muted">{statsGateways[3]} {intl.formatMessage({id: "transmis"})}</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-40 symbol-light-info mr-5">
                                <span className="symbol-label">
                                    <span className="svg-icon svg-icon-lg svg-icon-info">
                                        <span className={"fa fa-envelope"} style={{fontSize: "20px"}}></span>
                                    </span>
                                </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    {intl.formatMessage({id: "E-Mails"})}
                                </a>
                                <span className="text-muted">{statsGateways[4]} {intl.formatMessage({id: "transmis"})}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function getChartOption(layoutProps, data, max, intl) {
    var options = {
        series: [
            {
                name: intl.formatMessage({id: "SMS"}),
                data: data[Gateways.SMS]
            }, {
                name: intl.formatMessage({id: "Appels"}),
                data: data[Gateways.CALL]
            }, {
                name: intl.formatMessage({id: "Application"}),
                data: data[Gateways.SMARTPHONE]
            }, {
                name: intl.formatMessage({id: "E-Mails"}),
                data: data[Gateways.EMAIL]
            }
        ],
        chart: {
            type: "area",
            height: 200,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {},
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: "solid",
            opacity: 0.2,
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            opacity: 0.2,
            colors: ['#3699FF', '#FFA800', '#1BC5BD', '#8950FC']
        },
        xaxis: {
            categories: [
                intl.formatMessage({id: "Janvier"}),
                intl.formatMessage({ id: "Février"}),
                intl.formatMessage({ id: "Mars"}),
                intl.formatMessage({ id: "Avril"}),
                intl.formatMessage({ id: "Mai"}),
                intl.formatMessage({ id: "Juin"}),
                intl.formatMessage({ id: "Juillet"}),
                intl.formatMessage({ id: "Août"}),
                intl.formatMessage({ id: "Septembre"}),
                intl.formatMessage({ id: "Octobre"}),
                intl.formatMessage({ id: "Novembre"}),
                intl.formatMessage({ id: "Décembre"}),
            ],
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            },
            crosshairs: {
                show: false,
                position: "front",
                stroke: {
                    color: layoutProps.colorsGrayGray300,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        yaxis: {
            min: 0,
            max: max + 2,
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: "12px",
                    fontFamily: layoutProps.fontFamily
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: layoutProps.fontFamily
            },
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        colors: ['#E1F0FF', '#FFA800', '#C9F7F5', '#EEE5FF'],
        markers: {
            colors: ['#E1F0FF', '#FFA800', '#C9F7F5', '#EEE5FF'],
            strokeColor: ['#3699FF', '#FFA800', '#1BC5BD', '#8950FC'],
            strokeWidth: 3
        }
    };
    return options;
}
