import React, {useState, useEffect} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";
import {
    sortCaret, toAbsoluteUrl
} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import axios from "axios";
import {Detail} from "./Detail";

export const List = ({intl}) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({id: "Historique"}));

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [intervalResult, setIntervalResult] = useState();
    const [selectedNotification, setSelectedNotification] = useState();
    const [selectedNotificationNotificationUserGateway, setSelectedNotificationNotificationUserGateway] = useState([]);

    useEffect(() => {
        return () => {
            clearInterval(intervalResult);
        }
    }, [])

    const columns = [
        {
            dataField: 'id',
            text: intl.formatMessage({id: "Notification"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'customer.name',
            text: intl.formatMessage({id: "Client"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'sender.fullname',
            text: intl.formatMessage({id: "Envoyé par"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'sentAt',
            text: intl.formatMessage({id: "Envoyé le"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'acknowlegeable',
            text: intl.formatMessage({id: "Confirmable"}),
            sort: true,
            sortCaret: sortCaret,
            formatter: (value) => {
                return (<span
                    className={`label label-lg label-light-${
                        (value) ? 'success' : 'danger'
                    } label-inline`}
                >
                    {value ? intl.formatMessage({id: "Oui"}) : intl.formatMessage({id: "Non"})}
              </span>
            )}
        }, {
            dataField: 'typeLabel',
            text: intl.formatMessage({id: "Type"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'message',
            text: intl.formatMessage({id: "Message"}),
            sort: true,
            sortCaret: sortCaret,
            formatter: (value) => {
                if(value.length > 35)
                    return value.substr(0, 35) + "..."
                else
                    return value;
            }
        }, {
            dataField: "action",
            text: intl.formatMessage({id: "Actions"}),
            formatter: (
                cellContent,
                row,
            ) =>
            <>
                <OverlayTrigger
                    overlay={<Tooltip id="products-delete-tooltip">{intl.formatMessage({id: "Télécharger le rapport"})}</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        href={process.env.REACT_APP_API_URL + "report/" + row.reportId}
                        target={"_blank"}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                        </span>
                    </a>
                </OverlayTrigger>
                <OverlayTrigger
                    overlay={<Tooltip id="products-delete-tooltip">{intl.formatMessage({id: "Voir le détail"})}</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        onClick={() => {loadSelectedNotificationDetail(row); }}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
                        </span>
                    </a>
                </OverlayTrigger>
            </>,
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        }
    ];

    const loadSelectedNotificationDetail = (notification) => {
        setSelectedNotification(notification);
        setShowDetailsModal(true);

        /*axios.get("api/notifications/history/" + notification.id).then(response => {
            setSelectedNotificationNotificationUserGateway(response.data);
            setSelectedNotification(notification);
            setShowDetailsModal(true);
        });*/
    };


    /*if(selectedNotification && !intervalResult) {
        let interval = setInterval(() => loadSelectedNotificationDetail(selectedNotification), 5000);

        setIntervalResult(interval);
    }*/

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: "Historique des notifications"})}>

            </CardHeader>
            <CardBody>
                <Table id={"history-list"} columns={columns} entrypoint={"api/notifications/history"} />
                <Modal
                    size="xl"
                    show={showDetailsModal}
                    aria-labelledby="modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-sizes-title-lg">
                            {intl.formatMessage({id: "Détail"})}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedNotification && (
                            <Detail notification={selectedNotification} notificationUserGateway={selectedNotificationNotificationUserGateway} intl={intl} />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    clearInterval(intervalResult);

                                    setShowDetailsModal(false);
                                    setSelectedNotification(null);
                                    setSelectedNotificationNotificationUserGateway([]);
                                    setIntervalResult(null);
                                }}
                                className="btn btn-light btn-elevate"
                            >
                                {intl.formatMessage({id: "Fermer"})}
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </CardBody>
        </Card>
    );
};
