import React, {useContext, useState} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import {
    sortCaret, toAbsoluteUrl
} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";
import * as columnFormatters from "../../components/table/column-formatters";
import DeleteModal from "../../components/table/DeleteModal";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import Checkbox from "@material-ui/core/Checkbox";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {Switch} from "../../components/form/Switch";
import {ApplicationContext} from "../../library/ApplicationContext";

export const List = ({ history, intl }) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({id: "Liste des utilisateurs"}));

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [enabledUserOnly, setEnabledUserOnly] = useState(true);
    const [id, setId] = useState(0);
    const [rand, setRandom] = useState(0);

    const { application } = useContext(ApplicationContext);

    const toggleStatus = (status, row) => {
        row.status = status;

        axios({method: 'PUT', url: 'api/users/' + row.id, data: row}).then(() => setRandom(Math.floor(Math.random() * 9999)));
    };

    let columns = [
        {
            dataField: 'status',
            text: intl.formatMessage({id: "Inactif/Actif"}),
            formatter: (status, row) => <Switch color={'primary'} defaultChecked={!!status} onChange={event => toggleStatus(event.target.checked, row)} />,
            style: {
                width: "20px",
            },
        },
        {
            dataField: 'name',
            text: intl.formatMessage({id: "Nom"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'firstname',
            text: intl.formatMessage({id: "Prénom"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'email',
            text: intl.formatMessage({id: "E-Mail"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'roleName',
            text: intl.formatMessage({id: "Role"}),
            sort: true,
            sortCaret: sortCaret
        }
    ];


    application.catalog.customFields.forEach(customField => {
        if(customField.displayOnList === 1)
            columns.push({
                dataField: 'custom_field_' + customField.id ,
                text: intl.formatMessage({id: customField.name}),
                sort: false,
                sortCaret: sortCaret
            });
    });

    columns.push({
        dataField: "action",
        text: intl.formatMessage({id: "Actions"}),
        formatter: columnFormatters.ActionsColumnFormatter,
        formatExtraData: {
            intl: intl,
            openEditPage: id => history.push("/users/" + id + "/update"),
            extraActions: row =>
                <OverlayTrigger
                    overlay={<Tooltip id="products-delete-tooltip">{intl.formatMessage({id: "Réinitialiser mot de passe"})}</Tooltip>}>
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                        onClick={() => sendPassword(row.email)}
                    >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Redo.svg")} />
                    </span>
                    </a>
                </OverlayTrigger>
        },
        classes: "text-right pr-0",
        headerClasses: "text-right pr-3",
        style: {
            minWidth: "100px",
        },
    });


    const create = () => {
        history.push("/users/create");
    };

    const importUsers = () => {
        history.push("/users/import");
    };

    const openDeleteDialog = (value) => {
        setShowDeleteModal(true);
        setId(value);
    };

    const confirmDelete = () => {
        setDeleting(true);

        axios.delete("api/users/" + id).then(response => {
            setDeleting(false);
            setShowDeleteModal(false);
        });
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
    };

    const sendPassword = (email) => {
        axios.post('api/forgot-password', {email: email}).then(() => alert(intl.formatMessage({id: "Mot de passe envoyé"})));
    };

    const header = <>
        <label>{intl.formatMessage({id: "Afficher seulement les utilisateurs actifs"})}</label>
        <Checkbox checked={enabledUserOnly} onChange={e => setEnabledUserOnly(!enabledUserOnly)} />
    </>;

    return (
        <Card>
            <CardHeader title={intl.formatMessage({id: "Liste des utilisateurs"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={importUsers}
                    >
                        {intl.formatMessage({id: "Importer"})}
                    </button>
                    &nbsp;
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={create}
                    >
                        {intl.formatMessage({id: "Nouvel utilisateur"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Table id={"users-list"} columns={columns} header={header} entrypoint={"api/users"} extraQuery={enabledUserOnly ? "&status=1&rnd=" + rand : "&rnd=" + rand} deleting={deleting} />
                <DeleteModal show={showDeleteModal} deleting={deleting} confirmDelete={confirmDelete} cancelDelete={cancelDelete} />
            </CardBody>
        </Card>
    );
};
