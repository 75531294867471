import React, {useEffect, useState} from 'react';
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Form} from "./Form";
import axios from "axios";

export const Update = ({history, intl, match: {params: { id }}}) => {
    const suhbeader = useSubheader();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('');

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const data = await axios("/api/messages/" + id);
            setLoading(false);

            setData(data.data);
        };

        if (!data) {
            getData();
        }
    }, []);

    let handleSubmit;

    suhbeader.setTitle(intl.formatMessage({id: "Mise à jour du message"}));

    return (
        <Card>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Mise à jour du message"})}>
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => history.push("/messages")}
                        className="btn btn-light"
                    >
                        <i className="fa fa-arrow-left" />
                        {intl.formatMessage({id: "Retour"})}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary ml-2"
                        onClick={() => handleSubmit()}
                    >
                        {intl.formatMessage({id: "Enregistrer"})}
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Form method={"PUT"} data={data} setHandleSubmit={(func) => {handleSubmit = func}} loading={setLoading} history={history} intl={intl} />
            </CardBody>
        </Card>
    );
};
