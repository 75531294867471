import React, {Component} from "react";
import {
    Card,
    CardBody,
    CardHeader, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Alert, Modal} from "react-bootstrap";
import axios from "axios";
import {Formik, Field} from "formik";
import {Input} from "../../components/form/Input";
import Users from "./Users";
import Groups from "./Groups";
import Gateways from "./Gateways";
import {ApplicationContext} from "../../library/ApplicationContext";
import Map from "./Map";
import {NotificationType} from "../../library/Enums/NotificationType";
import {Gateways as GatewaysEnum} from "../../library/Enums/Gateways";

export class List extends Component {
    static contextType = ApplicationContext;


    state = {
        messages: [],
        data: {
            template: this.props.match.params.id ? this.context.application.catalog.templates.filter(e => e.id === this.props.match.params.id)[0] : null,
            type: this.props.location.pathname.split(/[?#]/)[0].indexOf('geofencing') === -1 ? NotificationType.NORMAL : NotificationType.GEOFENCING,
            content: "",
            message: "",
            acknowlegeable: 1,
            latitude: null,
            longitude: null,
            radius: this.props.location.pathname.split(/[?#]/)[0].indexOf('geofencing') === -1 ? null : 20000,
            maxTravelTime: null,
            group: null,
            users: [],
            groups: [],
            gateways: []
        },
        globalMessage: "",
        groupMessage: "",
        catalog: this.context.catalog,
        sentModalVisible: false,
        loading: false,
        resetForm: null,
        errors: [],
    }

    componentDidMount() {
        axios("/api/messages").then(response => {
            let data = this.state.data;

            if(data.template) {
                data.gateways = data.template.gateways;
                data.users = data.template.users;
                data.acknowlegeable = data.template.isAckDefault;
            }

            //geofencing
            if(data.type === NotificationType.GEOFENCING)
                data.gateways = this.context.application.catalog.gateways.filter(e => e.type === GatewaysEnum.SMARTPHONE);

            this.setState({
                messages: response.data.data,
                data: data,
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const type = this.props.location.pathname.split(/[?#]/)[0].indexOf('geofencing') === -1 ? 1 : 2;
        const template = this.props.match.params.id ? this.context.application.catalog.templates.filter(e => e.id === this.props.match.params.id)[0] : null;
        const acknowlegeable = this.props.match.params.id ? this.context.application.catalog.templates.filter(e => e.id === this.props.match.params.id)[0].isAckDefault : 1;
        const data = this.state.data;

        if(type !== this.state.data.type || template !== this.state.data.template) {
            data.type = type;
            data.template = template;
            data.acknowlegeable = acknowlegeable;

            if(data.template) {
                data.gateways = template.gateways;
                data.users = template.users;
            } else {
                data.gateways = [];
                data.users = [];
            }

            //geofencing
            if(data.type === NotificationType.GEOFENCING)
                data.gateways = this.context.application.catalog.gateways.filter(e => e.type === GatewaysEnum.SMARTPHONE);

            if(this.state.resetForm)
                this.state.resetForm({});

            this.setState({data: data, template: template});
        }
    }

    selectMessage(e, setFieldValue) {
        setFieldValue("message", e.target.value);

        const globalMessage = this.state.messages.filter(message => message.id === e.target.value)[0].content;

        this.setState({globalMessage: globalMessage});

        const content = this.state.groupMessage + " " + globalMessage;

        setFieldValue("content", content);
    }

    setGroupMessage(message, setFieldValue) {
        this.setState({groupMessage: message});

        const content = message + " " + this.state.globalMessage;

        setFieldValue("content", content);
    }

    send(values, resetForm) {
        this.setState({loading: true});

        axios({method: "POST", url: "/api/notifications", data: values}).then(response => {
            this.setState({sentModalVisible: true, loading: false, errors: []});
            resetForm({})
        }).catch(error => {
            // console.log("error send");
            // console.log("errors: " + JSON.stringify(error.response.data.errors));
            let state = {loading: false};

            if(error.response)
                state.errors = Object.entries(error.response.data.errors);

            this.setState(state);
        });
    }

    hideSentModal() {
        this.setState({sentModalVisible: false});

        this.props.history.push("/history");
    }

    renderOptions(formikProps) {
        let template = this.state.data.template ? this.state.data.template.type : null;
        let type = this.state.data.type ? this.state.data.type : null;
        let gateways = this.state.data.gateways ? this.state.data.gateways : [];

        if(!this.state.resetForm)
            this.setState({resetForm: formikProps.resetForm});

        if(type === 2)
            return <>
                <div className={"col-lg-4"}>
                    <Users formikProps={formikProps} loading={this.state.loading} gateways={gateways} />
                </div>
                <div className={"col-lg-8"}>
                    <Card>
                        <CardHeader title={this.props.intl.formatMessage({id: "Carte"})} />
                        <CardBody>
                            <Map formikProps={formikProps} />
                        </CardBody>
                    </Card>
                </div>
            </>

        switch (template) {
            case 1:
                return <>
                    <div className={"col-lg-4"}>
                        <Groups formikProps={formikProps} setGroupMessage={this.setGroupMessage.bind(this)} fieldName={"group"} loading={this.state.loading} />
                    </div>
                    <div className={"col-lg-8"}>
                        <Users formikProps={formikProps} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>
            case 2:
                return <div className={"col-lg-12"}>
                    <Users formikProps={formikProps} groups={this.state.data.template.groups} loading={this.state.loading} gateways={gateways} />
                </div>
            case 3:
                return <>
                    <div className={"col-lg-4"}>
                        <Gateways formikProps={formikProps} loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-4"}>
                        <Groups formikProps={formikProps} setGroupMessage={this.setGroupMessage.bind(this)} parentGroups={this.state.data.template.groups} fieldName={"groups"} multiple loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-4"}>
                        <Users formikProps={formikProps} parentGroups={this.state.data.template.groups} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>
            case 4:
                return <>
                    <div className={"col-lg-6"}>
                        <Gateways formikProps={formikProps} loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-6"}>
                        <Users formikProps={formikProps} groups={this.state.data.template.groups} users={this.state.data.template.users} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>
            case 5:
                return <>
                    <div className={"col-lg-6"}>
                        <Groups formikProps={formikProps} setGroupMessage={this.setGroupMessage.bind(this)} parentGroups={this.state.data.template.groups} fieldName={"group"} loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-6"}>
                        <Users formikProps={formikProps} parentGroups={this.state.data.template.groups} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>
            case 6:
                return <>
                    <div className={"col-lg-12"}>
                        <Users formikProps={formikProps} parentGroups={this.state.data.template.groups} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>
            default:
                return <>
                    <div className={"col-lg-4"}>
                        <Gateways formikProps={formikProps} loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-4"}>
                        <Groups formikProps={formikProps} setGroupMessage={this.setGroupMessage.bind(this)} fieldName={"groups"} multiple loading={this.state.loading} />
                    </div>

                    <div className={"col-lg-4"}>
                        <Users formikProps={formikProps} loading={this.state.loading} gateways={gateways} />
                    </div>
                </>;
        }
    }

    render() {
        return (
            <Formik
                initialValues={this.state.data}
                enableReinitialize={true}
                onSubmit={(values, { setErrors, resetForm }) => {
                    this.send(values, resetForm);
                }}
            >
                {props => (
                    <div className={"row"}>
                        <Modal
                            show={this.state.sentModalVisible}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {this.props.intl.formatMessage({id: "Notification"})}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    {this.props.intl.formatMessage({id: "La notification a été envoyée avec succès."})}
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={() => this.hideSentModal()}>{this.props.intl.formatMessage({id: "Fermer"})}</button>
                            </Modal.Footer>
                        </Modal>
                        <div className={"col-lg-12"}>
                            <Card>
                                {this.state.loading && <ModalProgressBar />}
                                <CardHeader title={this.props.intl.formatMessage({id: "Messages"})} />
                                <CardBody>
                                    {this.state.errors.length > 0 && (
                                        <Alert variant="danger" onClose={() => this.setState({errors: []})} dismissible>
                                            <Alert.Heading>{this.props.intl.formatMessage({id: "Une ou plusieurs erreurs sont survenues"})}</Alert.Heading>
                                            <p>
                                                {this.state.errors.map(value =>
                                                    <span key={value[0]}>{value[1]}<br /></span>
                                                )}
                                            </p>
                                        </Alert>
                                    )}

                                    {this.state.data.template && !this.state.data.template.isMsgVisible &&
                                        <p> <i>{this.props.intl.formatMessage({id: "Message prédéfini dans le groupe!"})} </i></p>
                                    }

                                    <Field
                                        style={{width: "100%"}}
                                        name="message"
                                        component={Input}
                                        type={ !this.state.data.template || this.state.data.template.isMsgVisible ?
                                                "select" : "hidden"}
                                        className={"mb-5"}
                                        label={this.props.intl.formatMessage({id: "Messages prédéfinis"})}
                                        data={this.state.messages}
                                        onChange={(e) => this.selectMessage(e, props.setFieldValue)}
                                        valueField={"id"}
                                        labelField={"name"}
                                        disabled={this.state.loading}
                                        
                                    />

                                    <Field
                                        style={{width: "100%"}}
                                        name="content"
                                        label={this.props.intl.formatMessage({id: "Message"})}
                                        component={Input}
                                        type={ !this.state.data.template || this.state.data.template.isMsgVisible ?
                                            "text" : "hidden"}                                        
                                        className={"mb-5"}
                                        disabled={this.state.loading}
                                        rows="10"
                                        multiline
                                    />

                                    {this.state.data.type === NotificationType.GEOFENCING && (<Field
                                        style={{width: "100%"}}
                                        name="maxTravelTime"
                                        component={Input}
                                        type={"select"}
                                        className={"mb-5"}
                                        label={this.props.intl.formatMessage({id: "Durée maximale du trajet (en minutes)"})}
                                        data={[
                                            { id: 10, name: this.props.intl.formatMessage({id : "10 minutes"})},
                                            { id: 15, name: this.props.intl.formatMessage({ id: "15 minutes" })},
                                            { id: 30, name: this.props.intl.formatMessage({ id: "30 minutes" })},
                                            { id: 45, name: this.props.intl.formatMessage({ id: "45 minutes" })},
                                            { id: 60, name: this.props.intl.formatMessage({ id: "60 minutes" })}
                                        ]}
                                        valueField={"id"}
                                        labelField={"name"}
                                        disabled={this.state.loading}
                                    />)}


                                    <Field
                                        name="acknowlegeable"
                                        label={this.props.intl.formatMessage({id: "Possibilité de confirmer par OUI ou NON"})}
                                        component={Input}
                                        type={ !this.state.data.template || this.state.data.template.isAckVisible ?
                                            "checkbox" : "hidden"}                                           
                                        disabled={this.state.loading}
                                        {...props}
                                    />
                                    

                                    <button
                                        type="button"
                                        onClick={props.handleSubmit}
                                        className="btn btn-primary mt-5"
                                        disabled={this.state.loading}
                                    >
                                        {this.props.intl.formatMessage({id: "Envoyer"})}
                                    </button>
                                    {(this.state.data.template && this.state.data.template.gateways.length > 0) && (
                                        <>
                                            <br/>
                                            <br/>
                                            <small>{this.props.intl.formatMessage({id: "Moyens d'alarmes"})}: {this.state.data.template.gateways.map(e => e.name).join(', ')}</small>
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </div>

                        {this.renderOptions(props)}
                    </div>
                )}
            </Formik>
        );
    }
};
