import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const initialValues = {
    email: "",
};

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    initialValues.email = "contact@yvesbochatay.ch";
}


function AskNewPassword(props) {
    const { intl } = props;
    const [isRequested, setIsRequested] = useState(false);
    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email("Format invalide")
            .required(
                intl.formatMessage({
                    id: "Champs requis",
                })
            ),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            requestPassword(values.email)
                .then(() => setIsRequested(true))
                .catch(() => {
                    setIsRequested(false);
                    setSubmitting(false);
                    setStatus("Ce compte n'existe pas");
                });
        },
    });

    return (
        <>
            <div className="login-form login-forgot" style={{ display: "block" }}>
                <div className="text-center mb-10 mb-lg-20">
                    <h3 className="font-size-h1">Obtenir son mot de passe</h3>
                    <div className="text-muted font-weight-bold">
                        Entrez votre adresse e-mail
                    </div>
                </div>
                {isRequested && (
                    <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                        <div className="alert-text font-weight-bold">Mot de passe envoyé</div>
                    </div>
                )}
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                >
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">
                                {formik.status}
                            </div>
                        </div>
                    )}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="E-Mail"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap flex-center">
                        <button
                            id="kt_login_forgot_submit"
                            type="submit"
                            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            disabled={formik.isSubmitting}
                        >
                            Envoyer
                        </button>
                        <Link to="/auth">
                            <button
                                type="button"
                                id="kt_login_forgot_cancel"
                                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                            >
                                Annuler
                            </button>
                        </Link>
                    </div>
                    <div className="mt-20">
                        <a href={"https://play.google.com/store/apps/details?id=ch.helpro.app"} target={"_blank"}>
                            <SVG
                                className="w-50"
                                src={toAbsoluteUrl("/media/play-store.svg")}>
                            </SVG>
                        </a>
                        <a href={"https://apps.apple.com/fr/app/helpro-acs/id1563748870"} target={"_blank"}>
                            <SVG
                                className="w-50"
                                src={toAbsoluteUrl("/media/app-store.svg")}>
                            </SVG>
                        </a>
                    </div>
                </form>
            </div>
        </>
    );
}

export default injectIntl(connect(null, auth.actions)(AskNewPassword));
