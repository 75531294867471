import React, {useEffect, useState} from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card as CardMetronic,
    CardBody,
    CardHeader, ModalProgressBar,
} from "../../../_metronic/_partials/controls";
import {Card, CardDeck} from "react-bootstrap";
import axios from "axios";

export const List = ({ history, intl }) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({id: "Crédits fournisseurs"}));

    const [loading, setLoading] = useState(false);
    const [gateways, setGateways] = useState([]);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const data = await axios("/api/credit");
            setLoading(false);

            setGateways(data.data.data);
        };

        if (gateways.length === 0 && !loading) {
            getData();
        }
    });

    return (
        <CardMetronic>
            {loading && <ModalProgressBar />}
            <CardHeader title={intl.formatMessage({id: "Crédits fournisseurs"})} />
            <CardBody>
                <CardDeck>
                    {gateways.map((gateway, idx) =>
                        <Card key={idx}>
                            <Card.Body>
                                <Card.Title>{gateway.provider.split('\\')[5]}</Card.Title>
                                <Card.Text>
                                    {gateway.credit}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">{intl.formatMessage({id: "Passerelle"})}: {gateway.provider.split('\\')[4]}</small>
                            </Card.Footer>
                        </Card>
                    )}
                </CardDeck>
            </CardBody>
        </CardMetronic>
    );
};
