import React, { useMemo } from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../../_metronic/layout/_core/MetronicLayout";
import {QuickUserToggler} from "./QuickUserToggler";
import {SwitchUser} from "../SwitchUser";

export function Topbar() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
        };
    }, [uiService]);

    return (
        <div className="topbar">
            {layoutProps.viewUserDisplay && <QuickUserToggler />}
        </div>
    );
}
