import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import {Modal} from "react-bootstrap";
import React from "react";
import {injectIntl} from "react-intl";

const DeleteModal = ({show, deleting, confirmDelete, cancelDelete, intl}) => {
    return (
        <Modal
            show={show}
            aria-labelledby="modal-sizes-title-lg"
        >
            {deleting && <ModalProgressBar variant="query" />}
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">
                    {intl.formatMessage({id: "Confirmation"})}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!deleting && (
                    <span>{intl.formatMessage({id: "Etes-vous sur de vouloir supprimer cet élément?"})}</span>
                )}
                {deleting && <span>{intl.formatMessage({id: "Effacement..."})}</span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={() => cancelDelete()}
                        className="btn btn-light btn-elevate"
                    >
                        {intl.formatMessage({id: "Annuler"})}
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={() => confirmDelete()}
                        className="btn btn-delete btn-danger btn-elevate"
                    >
                        {intl.formatMessage({id: "Effacer"})}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default injectIntl(DeleteModal);
