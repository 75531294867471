/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { useIntl } from "react-intl";

export function Providers({ className }) {
    const [loading, setLoading] = useState(false);
    const [gateways, setGateways] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        const getData = async () => {
            setLoading(true);

            const gateway = await axios("/api/dashboard/gateway");

            setGateways(gateway.data);

            setLoading(false);
        };

        if (gateways.length === 0 && !loading) {
            getData();
        }
    });

    return (
        <>
            <div className={`card card-custom ${className}`}>
                {loading && <ModalProgressBar />}
                {/* Header */}
                <div className="card-header border-0">
                    <h3 className="card-title font-weight-bolder text-dark">
                        {intl.formatMessage({id: "Status des passerelles"})}
                    </h3>
                </div>

                {/* Body */}
                <div className="card-body pt-2">
                    {gateways.map((gateway, idx) => {
                        return <div className={(gateways.length === idx + 1) ? "d-flex align-items-center" : "d-flex align-items-center mb-8"} key={idx}>
                            {gateway.services === gateway.servicesOk && (
                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-success">
                                            <span className={"fa fa-check-circle"} style={{fontSize: "20px", marginTop: "4px"}}></span>
                                        </span>
                                    </span>
                                </div>
                            )}

                            {(gateway.services !== gateway.servicesOk && gateway.servicesOk > 0) && (
                                <div className="symbol symbol-40 symbol-light-warning mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                                            <span className={"fa fa-exclamation-circle"} style={{fontSize: "20px", marginTop: "4px"}}></span>
                                        </span>
                                    </span>
                                </div>
                            )}

                            {gateway.servicesOk === 0 && (
                                <div className="symbol symbol-40 symbol-light-danger mr-5">
                                    <span className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                                            <span className={"fa fa-times-circle"} style={{fontSize: "20px", marginTop: "4px"}}></span>
                                        </span>
                                    </span>
                                </div>
                            )}

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    {intl.formatMessage({ id: gateway.name })}
                                </a>
                                <span className="text-muted">{gateway.providers}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>
        </>
    );
}
