import React, {Component} from "react";
import {GoogleMap, LoadScript, StandaloneSearchBox, Marker} from '@react-google-maps/api';
import { injectIntl } from "react-intl";

class Map extends Component {
    state = {
        styles: {
            map: {
                width: '100%',
                height: '400px',
                borderRadius: 5,
            },
            input: {
                boxSizing: 'border-box',
                border: '1px solid transparent',
                width: '240px',
                height: '32px',
                padding: '0 12px',
                borderRadius: '3px',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                fontSize: '14px',
                outline: 'none',
                textOverflow: 'ellipses',
                position: 'absolute',
                left: '50%',
                top: '10px',
                marginLeft: '-120px'
            }
        },
        center: {
            lat: 46.800663464,
            lng: 8.222665776,
        }
    }

    libraries = ["places"];
    circleRef = null;
    searchBoxRef = null;

    onUpdateCircle() {
        if(this.circleRef)
            this.updateFields(this.circleRef.state.circle.getCenter().lat(), this.circleRef.state.circle.getCenter().lng(), this.circleRef.state.circle.getRadius());
    }

    onDragEnd(e) {
        this.updateFields(e.latLng.lat(), e.latLng.lng(), 0);
        this.setState({center: {lat: e.latLng.lat(), lng: e.latLng.lng()}})
    }

    onPlacesChanged() {
        const places = this.searchBoxRef.getPlaces();

        if(places[0]) {
            this.setState({center: {lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng()}})

            this.updateFields(places[0].geometry.location.lat(), places[0].geometry.location.lng(), this.props.formikProps.values['radius']);
        }
    }

    updateFields(lat, lng, radius) {
        this.props.formikProps.setFieldValue("latitude", lat);
        this.props.formikProps.setFieldValue("longitude", lng);
        this.props.formikProps.setFieldValue("radius", radius);
    }

    render() {
        return (
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} region="CH" language={this.props.intl.locale} libraries={this.libraries}>
                <GoogleMap mapContainerStyle={this.state.styles.map} center={this.state.center} zoom={7.5} options={{controlSize: 25}}>
                    <StandaloneSearchBox onLoad={ref => this.searchBoxRef = ref} onPlacesChanged={() => this.onPlacesChanged()}>
                        <input type="text" placeholder={this.props.intl.formatMessage({id: "Entrez un lieu"})} style={this.state.styles.input} />
                    </StandaloneSearchBox>
                    <Marker position={{lat: this.state.center.lat, lng: this.state.center.lng}} draggable={true} onDragEnd={e => this.onDragEnd(e)} />
                </GoogleMap>
            </LoadScript>
        )
    }
}

export default injectIntl(Map);
