import React from "react";
import {useSubheader} from "../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";
import {
    sortCaret
} from "../../../_metronic/_helpers";
import Table from "../../components/table/Table";

export const List = ({intl}) => {
    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: "Changelog"}));

    const columns = [
        {
            dataField: 'date',
            text: intl.formatMessage({id: "Date"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'version',
            text: intl.formatMessage({id: "Version"}),
            sort: true,
            sortCaret: sortCaret
        }, {
            dataField: 'message',
            text: intl.formatMessage({id: "Message"}),
            sort: true,
            sortCaret: sortCaret
        }
    ];

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "Changelog"})}>

            </CardHeader>
            <CardBody>
                <Table id={"changelog-list"} columns={columns} entrypoint={"api/changelog"} />
            </CardBody>
        </Card>
    );
};
