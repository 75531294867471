import React from "react";
import {createMuiTheme, MuiThemeProvider, Switch as BaseSwitch} from "@material-ui/core";

export const Switch = (props) => {
    const theme = createMuiTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#F64E60"
                },
                colorPrimary: {
                    "&$checked": {
                        // Controls checked color for the thumb
                        color: "#50CD89"
                    }
                },
                track: {
                    backgroundColor: "#F64E60",
                    "$checked$checked + &": {
                        opacity: 0.3,
                        backgroundColor: "#50CD89"
                    }
                }
            }
        }
    });

    return (
        <MuiThemeProvider theme={theme}>
            <BaseSwitch {...props} />
        </MuiThemeProvider>
    );
};
