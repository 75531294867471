import React, { Component } from 'react'
import {Marker, OverlayView} from "@react-google-maps/api";

export class Markers extends Component {
    constructor(props) {
        super(props);

        this.state = {points: this.props.points, interval: null}
    }

    componentDidMount() {
        const interval = setInterval(() => this.setState({points: this.props.getPoints()}), 5000);

        this.setState({interval: interval})
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    getPixelPositionOffset(width, height) {
        return {
            x: -(width / 2),
            y: -(height * 3),
        };
    }

    render() {
        return this.state.points.map(point =>
            <Marker key={point.id} position={{lat: point.latitude, lng: point.longitude}} icon={{
                path: 3,
                strokeColor: point.acknowlegedStatus ? "green" : "red",
                scale: 5
            }}>
                <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={{lat: point.latitude, lng: point.longitude}} getPixelPositionOffset={this.getPixelPositionOffset}>
                    <div style={{ backgroundColor: `white`, border: `1px solid #ccc`, borderRadius: 5, padding: 5, width: 'fit-content', display: 'inline-block' }}>
                        {point.fullname}
                    </div>
                </OverlayView>
            </Marker>
        )
    }
}
